.footer {
  padding: 0 40px;
  max-width: 1560px;
  box-sizing: content-box;
  margin: 0 auto;
  position: relative;
  z-index: 4;
}

.footer-in {
  border-top: 1px solid #fff;
  padding: 40px 0;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.footer-stores {
  display: flex;
}

.footer-stores a:first-child {
  display: block;
  margin-right: 11px;
}

.footer-stores svg {
  display: block;
  transition: 300ms transform;
}

.footer-soc {
  display: flex;
  align-items: center;
  margin-right: -11px;
}

.footer-soc a {
  display: block;
  padding: 11px;
}

.footer-soc path[fill="white"] {
  transition: 300ms fill;
}
.footer-bottom {
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-bottom > div:last-child{
  text-align: right;
}

.footer-bottom a {
  text-decoration: none;
  border-bottom: 1px solid #fff;
  color: inherit !important;
  display: inline-block;
  line-height: 1.1;
  transition: 300ms;
}

.footer-bottom-el {
  margin-bottom: 5px;
  display: inline-block;
}

.footer__nav {
  display: flex;
}
.footer__nav__item{
  display: inline-block;
  margin: 0 25px;
  color: #fff !important;
  text-decoration: none;
  white-space: nowrap;
}
.footer__nav__item span{
  border-bottom: 1px solid transparent;
  transition: 300ms;
}
.footer-stores{margin: 0 25px;}
.footer .page-btn{margin: 0 25px;}
.footer-logo{margin-right: 25px;}
.footer-soc{margin-left: 14px;}
@media (min-width: 1000px) {
  .footer-bottom a:hover{
    border-bottom-color: transparent;
  }
  .footer-soc a:hover path[fill="white"] {
    fill: #00bdf2;
  }

  .footer-stores a:hover svg {
    transform: scale(0.9);
  }

  .footer__nav__item:hover  span{
    border-bottom-color: #fff;
  }
}
@media (max-width: 1680px) {
  .footer-stores,
  .footer__nav__item,
  .footer .page-btn{margin: 0 11px;}
  .footer-logo{margin-right: 11px;}
  .footer-soc{margin-left: 0;}
}
@media (max-width: 1470px) {
  .footer-soc a{padding: 5px;}
  .footer-stores,
  .footer__nav__item,
  .footer .page-btn{margin: 0 5px;}
  .footer-logo{margin-right: 5px;}
  .footer .page-btn{padding-left: 30px; padding-right: 30px;}
}
@media (max-width: 1360px) and (min-width: 1001px) {
  .footer .page-btn{display: none}
}
@media (max-width: 1120px) and (min-width: 1001px){
  .footer-soc{display: none}
}
@media (max-width: 1000px) {
  .footer-top, .footer-bottom{
    flex-direction: column;
    max-width: 290px;
    margin: 0 auto;
    box-sizing: content-box;
    text-align: center;
  }
  .footer-in{border-top-width: 2px; padding: 30px 0 45px; margin: 0 20px;}
  .footer-bottom > div:last-child{text-align: center; padding-top: 10px;}
  .footer-soc{padding: 30px 0;}
  .footer-stores{padding-top: 10px;}
  .footer__nav{flex-direction: column; padding-top: 30px;}
  .footer__nav__item{
    margin-bottom: 20px;
    font-size: 16px;
  }
  .footer .page-btn {
    width: 100%;
    margin-top: 30px;
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }
  .footer{padding: 0;}
  .footer-stores{margin: 0;}
}
