.header{
    max-width: 1560px;
    margin: 0 auto;
    position: relative;
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: content-box;
    border-bottom: 1px solid #FFFFFF;
}
@media (max-width: 1680px) {
    .header{padding: calc(40 / 1680 * 100vw) 0;}
}
@media (max-width: 1640px) {
    .header{margin: 0 40px;}
}
@media (max-width: 1000px) {
    .header{margin: 0 20px; padding: 20px 0; border-bottom-width: 2px;}
}
.header__logo, .header__icons--signup{
    width: 430px;
}
.header__icons--signup{
    justify-content: flex-end;
}
@media (max-width: 1000px) {
    .header__logo, .header__icons--signup{
        width: 100px;
        flex-shrink: 0;
    }
}

.header__nav {
    display: flex;
    margin: 0 55px;
}
.header__nav__item{
    display: inline-block;
    margin: 0 25px;
    color: #fff !important;
    text-decoration: none;
    white-space: nowrap;
}
.header__nav__item span{
    border-bottom: 1px solid transparent;
}

.header__icons {
    display: flex;
}
.header__icons__item{
    display: flex;
    align-items: center;
    margin-left: 30px;
    color: #fff !important;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
}
.header__icons__item:first-child{margin-left: 0;}
.header__icons__item svg{
    margin-right: 17px;
}
.header__icons__item span{
    border-bottom: 1px solid transparent;
}
.header__icons__item:first-child{margin-left: 0;}

.header__icons__item:last-child span{
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.header__icons__item--signup span{
    max-width: initial !important;
}

@media (max-width: 1360px) {
    /*.header{font-size: 15px;}*/
    .header__nav__item{margin: 0 15px;}
    .header__icons__item{margin-left: 15px;}
    /*.header__nav{margin: 0 20px;}*/
    .header__icons__item svg {
        margin-right: 7px;
        width: 25px;
    }
}

@media (max-width: 1200px) {
    .header{font-size: 13px;}
    .header__nav__item{margin: 0 15px;}
    .header__icons__item{margin-left: 15px;}
    .header__nav{margin: 0 20px;}
    .header__icons__item svg {
        margin-right: 7px;
        width: 20px;
    }
    .header__logo svg{width: 100px; height: 35px;}

    .header__icons__item:last-child span{
        max-width: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (min-width: 1000px) {
    .header__nav__item:hover span{
        border-bottom-color: #fff;
    }
    .header__icons__item:hover span{
        border-bottom-color: #fff;
    }
}
@media (max-width: 1000px) {
    .header__icons__item{margin-left: 20px;}
    .header__icons__item svg{margin-right: 0; width: 30px}
    .header__logo svg{height: 27px;}
}
.lk_el3{pointer-events: none}

.header__icons__item--close svg{width: 20px}
.header__icons__item--menu svg{width: 20px}
.header{
    z-index: 999;
}
.header__menu-mobile{
    position: absolute;
    top: 0; right: 0;
    width: 100%;
    z-index: 998;
    background: #000;
    height: 100vh;

    /*position: fixed;*/
    /*top: 0;*/
    /*right: 0;*/
    /*width: 100%;*/
    /*z-index: 99999999;*/
    /*height: 100vh;*/
    /*overflow: auto;*/
}
.header__menu-mobile__bg {
    background: #000;
    margin-top: 77px;
    overflow: auto;
    height: calc(100% - 77px);
    -webkit-overflow-scrolling: touch;
}
.header__menu-mobile__bottom-info{
    padding-bottom: 80px;
}
.header__icons__item.header__icons__item--close {
    z-index: 999999999;
    position: relative;
}

.header__nav-mobile{
    padding: 30px 20px 20px;
    margin: 0 20px 40px;
    border-bottom: 2px solid #fff;
}
.header__nav-mobile__item{
    text-transform: uppercase;
    display: block;
    color: #fff !important;
    text-decoration: none;
    margin-bottom: 18px;
}

.header__menu-mobile__bottom{
    max-width: 290px;
    margin: 0 auto;
    box-sizing: content-box;
}
.header__menu-mobile__bottom .footer-soc{
    justify-content: center;
    padding-top: 30px;
}
.header__menu-mobile .page-btn{
    width: 100%;
    margin-bottom: 40px;
}
.header__menu-mobile__bottom-info {
    color: #fff;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.header__menu-mobile__bottom-info a{color: #fff !important;}
.header__menu-mobile__bottom-info > div{margin-bottom: 20px;}

.header__menu-mobile__bottom .footer-stores{padding-top: 0;}


.page-btn{
    font-size: 12px;
    line-height: 15px;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff !important;
    padding: 22px 40px 23px;
    background: #00BDF2;
    margin: 0 auto;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    text-decoration: none;
}
.page-btn.disabled{
    pointer-events: none;
    opacity: .5;
}
.page-btn span{
    border-bottom: 1px solid transparent;
    transition: 300ms;
}
@media (min-width: 1000px) {
    .page-btn:hover span{border-bottom-color: #fff;}
}
@media (max-width: 1680px) and (min-width: 1000px){

    .page-btn{
        line-height: 1.1;
        font-size: calc(12 / 1680 * 100vw);
        padding: calc(22 / 1680 * 100vw) calc(40 / 1680 * 100vw) calc(23 / 1680 * 100vw);
    }
}
