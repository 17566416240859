
.store{
    background: #000;
    line-height: 1.23;
    overflow: hidden;
    width: 100%;
}
.store-content{
    position: relative;
}
.store-content__bg{
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    pointer-events: none;
}
.store-content__bg span{
    position: absolute;
    top: -20px; left: 50%;
    width: 1547px; height: 1547px;
    margin-left: calc(-1547px / 2);
    background-image: url(./assets/bg.png?2);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 1547px;
    opacity: .5;
}
.store-content__bg span:nth-child(1){
    animation: loader1 25s ease-in-out infinite;
}
.store-content__bg span:nth-child(2){
    background-size: 1289px;
    animation: loader2 27s ease-in-out infinite;
}
.store-content__bg span:nth-child(3){
    background-size: 1074px;
    animation: loader3 29s ease-in-out infinite;
}
.store-content__bg span:nth-child(4){
    background-size: 895px;
    animation: loader4 31s ease-in-out infinite;
}

@keyframes loader1 {
    0% {
        transform: rotate(-15deg);
    }
    100% {
        transform: rotate(345deg);
    }
}

@keyframes loader2 {
    0% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(350deg);
    }
}

@keyframes loader3 {
    0% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(355deg);
    }
}

@keyframes loader4 {
    0% {
        transform: rotate(-0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.store-content__in{
    padding: 90px 40px 150px;
    max-width: 1105px;
    margin: 0 auto;
    position: relative;
}
.store-title{
    font-weight: 800;
    font-size: 60px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px;
}
.store-subtitle{
    font-size: 24px;
    color: #fff;
    text-align: center;
    margin-bottom: 90px;
}
.store-input{
    position: relative;
    margin-bottom: 50px;
}
.store-input svg{
    position: absolute;
    top: 50%; right: 20px;
    transform: translateY(-50%);
    pointer-events: none;
}
.store-input input{
    width: 100%;
    border: 2px solid #FFFFFF;
    background: none;
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
    padding: 16px 22px 18px;
    color: #fff;
    font-family: inherit;
    font-size: 18px;
    box-shadow: 0 0 40px rgba(0, 189, 242, .8), inset 0 0 20px rgba(0, 189, 242, .8);
}

.store-grid{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}
.store-grid__column{
    width: calc(100% / 3 - 13px);
}
.store-grid__item{
    background: #fff;
    width: 100%;
    margin-bottom: 22px;
    padding: 40px 27px 50px;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 14px;
}
.store-grid__title{
    font-size: 24px;
    margin-bottom: 1px;
}
.store-grid__floor{
    font-size: 18px;
    margin-bottom: 20px;
}
.store-grid__item p, .store-grid__text{
    margin-bottom: 20px;
}
.store-grid__item img{
    max-width: 100%;
    margin-bottom: 20px;
}
.store-grid__link{
    cursor: pointer;
    font-size: 12px;
    color: rgba(0, 189, 242, 1);
    display: inline-block;
    border-bottom: 1px solid;
    line-height: 1;
}
.store-btn{
    font-size: 12px;
    line-height: 15px;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
    padding: 22px 40px 23px;
    background: #00BDF2;
    width: 176px;
    margin: 0 auto;
    cursor: pointer;
    box-sizing: border-box;
}
.store-btn span{
    border-bottom: 1px solid transparent;
    transition: 300ms;
}
@media (min-width: 1000px) {
    .store-btn:hover span{
        border-color: #fff;
    }
}

.store-icon{
    position: absolute;
    top: 0; left: 50%;
    z-index: 1;
    pointer-events: none;
}
.store-icon1{
    transform: translate(184px, 268px);
}
.store-icon2{
    transform: translate(-630px, 410px);
}
.store-icon3{
    transform: translate(495px, 930px);
}
.store-icon4{
    transform: translate(-630px, 1930px);
}
.store-icon5{
    transform: translate(500px, 2450px);
}
.store-icon6{
    transform: translate(-710px, 2908px);
}
.store-icon1 img, .store-icon4 img{
    animation: spin2 40s infinite linear
}
.store-icon2 img, .store-icon5 img{
    animation: spin1 25s infinite linear
}
.store-icon3 img, .store-icon6 img{
    animation: spin2 30s infinite linear;
}

.store-bg1, .store-bg3 {
    position: absolute;
    width: 918px;
    height: 510px;
    left: -360px;
    top: 350px;
    background: #ff54c4;
    filter: blur(200px);
}
.store-bg3{
    top: 1900px
}

.store-bg2, .store-bg4{
    position: absolute;
    width: 918px;
    height: 510px;
    right: -350px;
    top: 920px;
    background: #00bdf2;
    filter: blur(200px);
}
.store-bg4{
    top: 2800px
}
@media (max-width: 1000px) {
    .store-content{
        max-width: 400px;
        margin-left: auto; margin-right: auto;
    }
    .store-grid{
        flex-direction: column;
        /*max-width: 295px;*/
        margin: 0 auto;
    }
    .store-grid__item img{
        width: 295px;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    .store-grid__column{
        width: 100%;
    }
    .store-title{
        font-size: 30px;
        margin-bottom: 23px;
    }
    .store-subtitle{
        font-size: 16px;
        margin-bottom: 45px;
    }
    .store-content__in{
        padding-top: 30px;
        padding-bottom: 80px;
    }


    .store-content__bg span{
        top: -20px;
        width: 575px; height: 575px;
        margin-left: calc(-575px / 2);
        background-size: 575px;
    }
    .store-content__bg span:nth-child(2){
        background-size: 425px;
    }
    .store-content__bg span:nth-child(3){
        background-size: 345px;
    }
    .store-content__bg span:nth-child(4){
        background-size: 280px;
    }

    .store-bg1, .store-bg3 {
        width: 565px;
        height: 203px;
        left: -300px;
        top: 200px;
        filter: blur(100px);
    }
    .store-bg3{
        top: 1900px
    }

    .store-bg2, .store-bg4{
        width: 565px;
        height: 203px;
        right: -300px;
        top: 920px;
        filter: blur(100px);
    }
    .store-bg4{
        top: 2800px
    }
    .store-btn{width: 100%; text-align: center}
    .store-icon1{
        transform: translate(120px, 1265px);
    }
    .store-icon2{
        transform: translate(-200px, 730px);
    }
    .store-icon3{
        transform: translate(100px, 158px);
    }
    .store-icon3 img, .store-icon2 img, .store-icon1 img{width: 50px}
}