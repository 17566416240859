.points{
    position: relative;
    height: 850px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 190px;
    box-sizing: border-box;
}
.points__bg{
    position: absolute;
    top: -200px; left: 0; width: 100%;
    height: 1650px;
    overflow: hidden;
}

.points__pink{
    top: -100px; left: 50%; margin-left: -1300px;
    opacity: .6;
}
.points__blue{
    top: 400px; left: 50%; margin-left: -300px;
}
.points__text{
    text-align: center;
    position: relative;
}
.points__text__title{
    color: #fff;
    font-size: 40px;
    margin-bottom: 35px;
    text-transform: uppercase;
    line-height: 1.2;
    font-weight: 800;
}
.points__icon{
    position: absolute;
    top: 0; left: 50%;
}
.points__icon img{width: 100%; height: auto}
.points__icon--1{
    width: 236px; height: 236px;
    margin-left: -890px;
    margin-top: 405px;
}
.points__icon--2{
    width: 142px; height: 142px;
    margin-left: -646px;
    margin-top: 560px;
}
.points__icon--3{
    width: 349px; height: 349px;
    margin-left: -558px;
    margin-top: 245px;
}
.points__icon--4{
    width: 147px; height: 147px;
    margin-left: 135px;
    margin-top: 379px;
}
.points__icon--5{
    width: 349px; height: 349px;
    margin-left: 310px;
    margin-top: 292px;
}
.points__icon--6{
    width: 270px; height: 270px;
    margin-left: 674px;
    margin-top: 347px;
}
.points__icon--7{
    width: 210px; height: 210px;
    margin-left: 311px;
    margin-top: 746px;
}
.points__icon--8{
    width: 210px; height: 210px;
    margin-left: -885px;
    margin-top: 780px;
}
.points__icon--9{
    width: 147px; height: 147px;
    margin-left: 621px;
    margin-top: 644px;
}
.points__icon--10{
    width: 270px; height: 270px;
    margin-left: 610px;
    margin-top: 827px;
}
.points__icon--11{
    width: 359px; height: 359px;
    margin-left: -650px;
    margin-top: 740px;
}
.points__eligibility{

}
.points__eligibility .eligibility_Footer_Box_Wrapper__bg--1:before{
    margin-left: 366px;
    margin-top: -290px;
}
.points__eligibility .eligibility_Footer_Box_Wrapper__bg--2:after{
    margin-left: -1040px;
    margin-top: -450px;
}


.points-icon2{
    margin-top: 403px;
    margin-left: -133px;
}
.points-icon3{
    margin-top: 827px;
    margin-left: -247px;
}
.points-icon4{
    margin-top: 982px;
    margin-left: 133px;
}

@media (max-width: 1680px) {
    .points-icon2{
        margin-top: calc(403 / 1680 * 100vw);
        margin-left: calc(-133 / 1680 * 100vw);
    }
    .points-icon3{
        margin-top: calc(827 / 1680 * 100vw);
        margin-left: calc(-247 / 1680 * 100vw);
    }
    .points-icon4{
        margin-top: calc(982 / 1680 * 100vw);
        margin-left: calc(133 / 1680 * 100vw);
    }
    .points{
        height: calc(850 / 1680 * 100vw);
        padding-top: calc(190 / 1680 * 100vw);
    }
    .points__bg{
        top: calc(-200 / 1680 * 100vw);
        height: calc(1650 / 1680 * 100vw);
    }

    .points__pink{
        top: calc(-100 / 1680 * 100vw);
        margin-left: calc(-1300 / 1680 * 100vw);
    }
    .points__blue{
        top: calc(400 / 1680 * 100vw);
        margin-left: calc(-300 / 1680 * 100vw);
    }
    .points__text__title{
        font-size: calc(40 / 1680 * 100vw);
        margin-bottom: calc(35 / 1680 * 100vw);
    }
    .points__icon--1{
        width: calc(236 / 1680 * 100vw); height: calc(236 / 1680 * 100vw);
        margin-left: calc(-890 / 1680 * 100vw);
        margin-top: calc(405 / 1680 * 100vw);
    }
    .points__icon--2{
        width: calc(142 / 1680 * 100vw); height: calc(142 / 1680 * 100vw);
        margin-left: calc(-646 / 1680 * 100vw);
        margin-top: calc(560 / 1680 * 100vw);
    }
    .points__icon--3{
        width: calc(349 / 1680 * 100vw); height: calc(349 / 1680 * 100vw);
        margin-left: calc(-558 / 1680 * 100vw);
        margin-top: calc(245 / 1680 * 100vw);
    }
    .points__icon--4{
        width: calc(147 / 1680 * 100vw); height: calc(147 / 1680 * 100vw);
        margin-left: calc(135 / 1680 * 100vw);
        margin-top: calc(379 / 1680 * 100vw);
    }
    .points__icon--5{
        width: calc(349 / 1680 * 100vw); height: calc(349 / 1680 * 100vw);
        margin-left: calc(310 / 1680 * 100vw);
        margin-top: calc(292 / 1680 * 100vw);
    }
    .points__icon--6{
        width: calc(270 / 1680 * 100vw); height: calc(270 / 1680 * 100vw);
        margin-left: calc(674 / 1680 * 100vw);
        margin-top: calc(347 / 1680 * 100vw);
    }
    .points__icon--7{
        width: calc(210 / 1680 * 100vw); height: calc(210 / 1680 * 100vw);
        margin-left: calc(311 / 1680 * 100vw);
        margin-top: calc(746 / 1680 * 100vw);
    }
    .points__icon--8{
        width: calc(210 / 1680 * 100vw); height: calc(210 / 1680 * 100vw);
        margin-left: calc(-885 / 1680 * 100vw);
        margin-top: calc(780 / 1680 * 100vw);
    }
    .points__icon--9{
        width: calc(147 / 1680 * 100vw); height: calc(147 / 1680 * 100vw);
        margin-left: calc(621 / 1680 * 100vw);
        margin-top: calc(644 / 1680 * 100vw);
    }
    .points__icon--10{
        width: calc(270 / 1680 * 100vw); height: calc(270 / 1680 * 100vw);
        margin-left: calc(610 / 1680 * 100vw);
        margin-top: calc(827 / 1680 * 100vw);
    }
    .points__icon--11{
        width: calc(359 / 1680 * 100vw); height: calc(359 / 1680 * 100vw);
        margin-left: calc(-650 / 1680 * 100vw);
        margin-top: calc(740 / 1680 * 100vw);
    }
    .points__eligibility .eligibility_Footer_Box_Wrapper__bg--1:before{
        margin-left: calc(366 / 1680 * 100vw);
        margin-top: calc(-290 / 1680 * 100vw);
    }
    .points__eligibility .eligibility_Footer_Box_Wrapper__bg--2:after{
        margin-left: calc(-1040 / 1680 * 100vw);
        margin-top: calc(-450 / 1680 * 100vw);
    }
}


@media (max-width: 1000px) {
    .points__text__title {
        font-size: 30px;
        margin-bottom: 0;
    }
    .points__text__title br {
        display: none;
    }
    .points__text .page-btn {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: calc(100% - 80px);
    }

    .points {
        height: 712px;
        padding-top: 0;
        margin-top: 69px;
        align-items: flex-start;
    }

    .points__bg {
        top: 0;
        height: 920px;
        pointer-events: none;
    }

    .points__text {
        padding: 0 40px;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }


    .points__icon img {
        display: none;
    }
    .points__icon {
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .points__icon--1 {
        width: 137px;
        height: 137px;
        margin-left: -230px;
        margin-top: 65px;
        background-image: url("images/ikea_mob.png?2");
    }

    .points__icon--2 {
        width: 83px;
        height: 83px;
        margin-left: -41px;
        margin-top: 115px;
        background-image: url("images/letual_mob.png?2");
    }
    .points__icon--3 {
        width: 86px;
        height: 86px;
        margin-left: 98px;
        margin-top: 69px;
        background-image: url("images/goldApple_mob.png?2");
    }

    .points__icon--4 {
        width: 203px;
        height: 203px;
        margin-left: -185px;
        margin-top: 175px;
        background-image: url("images/miuz_mob.png?2");
    }

    .points__icon--5 {
        width: 86px;
        height: 86px;
        margin-left: 20px;
        margin-top: 180px;
        background-image: url("images/bodyShop_shop.png?2");
    }
    .points__icon--6 {
        width: 157px;
        height: 175px;
        margin-left: 98px;
        margin-top: 133px;
        background-image: url("images/togo_mob.png?2");
    }
    .points__icon--7 {
        width: 202px;
        height: 202px;
        margin-left: -24px;
        margin-top: 264px;
        background-image: url("images/uni_mob.png?2");
    }
    .points__icon--8 {
        width: 107px;
        height: 107px;
        margin-left: -211px;
        margin-top: 356px;
        background-image: url("images/erborian_mob.png?2");
    }
    .points__icon--9 {
        width: 208px;
        height: 208px;
        margin-left: -167px;
        margin-top: 419px;
        background-image: url("images/sams_mob.png?2");
    }

    .points__icon--10 {
        width: 157px;
        height: 157px;
        margin-left: 25px;
        margin-top: 450px;
        background-image: url("images/rivGosh_mob.png?2");
    }
    .points__icon--11 {
        width: 121px;
        height: 121px;
        margin-left: 145px;
        margin-top: 371px;
        background-image: url("images/epa_mob.png?2");
    }


}

@media (max-width: 767px) {
    .points__text__title {
        font-size: 20px;
    }
}