.eligibility_Box {
    padding: 150px 0 0 120px;
    position: relative;
    max-width: 1410px;
    margin: 0 auto;
    box-sizing: border-box;
}

.eligibility_title {
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 80px;
}
.eligibility_Footer_Btn_Wrapper {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    padding-right: 120px;
}
.eligibility_Footer_Btn {
    align-items: center;
    padding: 22px 40px 22px;
    background: #00bdf2;
    border: none;
    outline: none;
    color: #fff;
}

.eligibility_Footer_Box_Wrapper {
    display: flex;
    justify-content: center;
    margin-top: 190px;
    position: relative;
}
.eligibility_Footer_Box_Wrapper__bg{
    position: absolute;
    top: 50%; left: 0; width: 100%; height: 1100px;
    transform: translateY(-50%);
    overflow: hidden;
    pointer-events: none;
}
.eligibility_Footer_Box_Wrapper__bg--1:before, .eligibility_Footer_Box_Wrapper__bg--2:after{
    content: '';
    position: absolute; top: 50%; left: 50%;
    background-image: url("assets/1.png?2");
    background-position: 50% 50%; background-repeat: no-repeat; background-size: contain;
    width: 620px; height: 620px;
    margin-left: -1004px;
    margin-top: -190px;
    animation: spin2 linear 11s infinite;
}
.eligibility_Footer_Box_Wrapper__bg--2:after{
    background-image: url("assets/2.png?2");
    margin-left: 380px; margin-top: -590px;
    animation: spin1 linear 11s infinite;
}

.eligibility_Footer_Box {
    width: 1100px;
    height: 262px;
    background: linear-gradient(245.5deg, #842d70 16.89%, #0e83f0 93.78%);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 170px;
    padding-left: 130px;
    padding-right: 130px;
    box-sizing: border-box;
    position: relative;
}

.eligibility_Footer_Box > div{
    font-style: normal;
    font-weight: 100;
    font-size: 30px;
    color: #FFFFFF;
    width: 100%;
    text-transform: uppercase;
    line-height: 1.3;
}
.eligibility_Footer_Box .page-btn{
    position: relative;
    z-index: 99;
}


@media (max-width: 1680px) {
    .eligibility_Box {
        padding: calc(150 / 1680 * 100vw) 0 0 calc(120 / 1680 * 100vw);
        max-width: calc(1410 / 1680 * 100vw);
    }

    .eligibility_title {
        font-size: calc(50 / 1680 * 100vw);
        margin-bottom: calc(80 / 1680 * 100vw);
    }
    .eligibility_Footer_Btn_Wrapper {
        margin-top: calc(60 / 1680 * 100vw);
        padding-right: calc(120 / 1680 * 100vw);
    }
    .eligibility_Footer_Btn {
        padding: calc(22 / 1680 * 100vw) calc(40 / 1680 * 100vw) calc(22 / 1680 * 100vw);
    }

    .eligibility_Footer_Box_Wrapper {
        margin-top: calc(190 / 1680 * 100vw);
    }

    .eligibility_Footer_Box_Wrapper__bg{
        height: calc(1100 / 1680 * 100vw);
    }
    .eligibility_Footer_Box_Wrapper__bg--1:before, .eligibility_Footer_Box_Wrapper__bg--2:after{
        width: calc(620 / 1680 * 100vw); height: calc(620 / 1680 * 100vw);
        margin-left: calc(-1004 / 1680 * 100vw);
        margin-top: calc(-190 / 1680 * 100vw);
    }
    .eligibility_Footer_Box_Wrapper__bg--2:after{
        margin-left: calc(380 / 1680 * 100vw); margin-top: calc(-590 / 1680 * 100vw);
    }
    .eligibility_Footer_Box {
        width: calc(1100 / 1680 * 100vw);
        height: calc(262 / 1680 * 100vw);
        margin-bottom: calc(170 / 1680 * 100vw);
        padding-left: calc(130 / 1680 * 100vw);
        padding-right: calc(130 / 1680 * 100vw);
    }

    .eligibility_Footer_Box div{
        font-size: calc(30 / 1680 * 100vw);
    }
}

/*@media (min-width: 1001px) {*/
    .eligibility_Box .slick-slide {
        float: none;
        width: auto !important;
    }
    .eligibility_Box .slick-track {
        width: auto !important;
        transform: none !important;
    }
    .eligibility_Box .slick-list {
        overflow: visible;
        height: auto !important;
    }
/*}*/



@media (max-width: 1000px) {
    .eligibility_title {
        font-size: 30px;
        margin-bottom: 0;
    }

    .eligibility_Box {
        padding: 20px 0 0 0;
        max-width: none;
        margin-top: 50px;
    }




    .eligibility_Box .flex_Left,
    .eligibility_Box .flex_Right
    {
        justify-content: center;
    }
    .icon_Box {
        width: 295px;
        height: auto;
        min-width: auto;
        margin-right: 0;
    }
    .eligibility_Card_Context {
        font-size: 24px;
        text-align: center;
    }
    .eligibility_Card_Context + .uniq_Text {
        text-align: center;
    }
    .icon_Box svg {
        display: none;
    }
    .num1 .icon_Box {
        width: 295px;
        height: 278px;
        background: url("assets/num1.png?2") center center no-repeat;
        background-size: contain;
    }
    .num2 .icon_Box {
        width: 294px;
        height: 278px;
        background: url("assets/num2.png?2") center center no-repeat;
        background-size: contain;
    }
    .num3 .icon_Box {
        width: 279px;
        height: 278px;
        background: url("assets/num3.png?2") center center no-repeat;
        background-size: contain;
    }
    .num4 .icon_Box {
        width: 279px;
        height: 278px;
        background: url("assets/num4.png?2") center center no-repeat;
        background-size: contain;
    }
    .num5 .icon_Box {
        width: 279px;
        height: 278px;
        background: url("assets/num5.png?2") center center no-repeat;
        background-size: contain;
    }

    .icon_Body {
        width: auto;
        padding: 0 40px;
    }
    .uniq_Text {
        font-size: 16px;
        line-height: 20px;
        margin: 13px 0 0 0;
    }
    .uniq_Text br {
        display: none;
    }

    .icon_Body .page-btn {
        margin: 25px auto 0 auto;
        display: block;
    }
    .eligibility_Card_Context br {
        display: none;
    }
    .eligibility_Footer_Btn_Wrapper {
        margin-top: 0;
        padding-right: 0;
    }
    .eligibility_Footer_Btn_Wrapper .page-btn {
        /*background: transparent;*/
        font-weight: normal;
    }
    .btn_Wrapper {
        display: block;
    }
    .eligibility_Btn_Icon {
        border-radius: 5px;
        font-size: 16px;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        padding: 10px 20px;
        max-width: 160px;
        box-sizing: border-box;
    }
    .eligibility_Btn_Icon span {
        margin-left: 0;
    }



    .eligibility_Footer_Box_Wrapper {
        margin-top: 80px;
        margin-bottom: 70px;
    }
    .eligibility_Footer_Box_Wrapper__bg {
        height: auto;
    }
    .eligibility_Footer_Box {
        width: 100%;
        height: auto;
        margin-bottom: 0;
        padding: 49px 0;
        display: block;
        border-radius: 0;
    }
    .eligibility_Footer_Box .page-btn {
        margin: 25px auto 0 auto;
        display: block;
        width: auto;
        max-width: 215px;
    }
    .eligibility_Footer_Box div {
        font-weight: 100;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
    }
    .eligibility_Box .slick-list{transition: height 300ms;}
    .eligibility_Box .slick-track{
       /* display: flex !important;*/
        margin-bottom: 20px;
    }
    .eligibility_Box .slick-slide{
        margin-bottom: 40px;
    }
}



