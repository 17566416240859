.eligibility_Card_Context {
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 100%;
  /*margin-left: 20px;*/
}

.icon_Box {
  width: 280px;
  height: 280px;
  min-width: 280px;
  margin-right: 20px;
}
.icon_Box svg{width: 100%; height: auto}

.uniq_Text {
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  margin: 20px 0 40px;
}

.eligibility_Btn {
  padding: 22px 40px 22px;
  background: #00bdf2;
  outline: none;
  border: none;
  color: #ffffff;
  margin: 30px 0 0 20px;
}

.eligibility_Btn_Icon {
  background: #00bdf2;
  border-radius: 5px;
  color: #fff;
  font-weight: 200;
  font-size: 14px;
  border: none;
  outline: none;
  margin-top: 25px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 20px;
  text-decoration: none;
  transition: transform .3s;
}
.eligibility_Btn_Icon span {
  margin-left: 15px;
}
@media (min-width: 1000px) {
  .eligibility_Btn_Icon:hover {
    transform: scale(0.9);
  }
}
.icon_Wrapper {
  width: 65%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -40px;
}

.flex_Left {
  display: flex;
  justify-content: flex-start;
}
.flex_Right {
  display: flex;
  justify-content: flex-end;
}
.btn_Wrapper {
  display: flex;
  align-items: center;
}
.icon_Body{
  width: 100%;
}

@media (max-width: 1680px) {
  .eligibility_Card_Context {
    font-size: calc(36 / 1680 * 100vw);
  }

  .icon_Box {
    width: calc(280 / 1680 * 100vw);
    height: calc(280 / 1680 * 100vw);
    min-width: calc(280 / 1680 * 100vw);
    margin-right: calc(20 / 1680 * 100vw);
  }

  .uniq_Text {
    font-size: calc(14 / 1680 * 100vw);
    line-height: calc(17 / 1680 * 100vw);
    margin: calc(20 / 1680 * 100vw) 0 calc(40 / 1680 * 100vw);
  }

  .eligibility_Btn {
    padding: calc(22 / 1680 * 100vw) calc(40 / 1680 * 100vw) calc(22 / 1680 * 100vw);
    margin: calc(30 / 1680 * 100vw) 0 0 calc(20 / 1680 * 100vw);
  }

  .eligibility_Btn_Icon {
    border-radius:calc( 5 / 1680 * 100vw);
    font-size: calc(14 / 1680 * 100vw);
    margin-top: calc(25 / 1680 * 100vw);
    margin-left: calc(10 / 1680 * 100vw);
    padding:calc(9 / 1680 * 100vw) calc(20 / 1680 * 100vw);
  }
  .eligibility_Btn_Icon span {
    margin-left: calc(15 / 1680 * 100vw);
  }

  .icon_Wrapper {
    margin-top: calc(-40 / 1680 * 100vw);
  }

}