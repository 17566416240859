.partners_Box {
    padding: 150px 0 0;
    position: relative;

}
.partners-icon3{
    margin-top: 79px;
    margin-left: 303px;
}
.partners-icon2{
    margin-top: 250px;
    margin-left: -314px;
}
.partners-icon4{
    margin-top: 490px;
    margin-left: 450px;
}
.partners_Title {
    font-size: 50px;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
}
.partners_Logo_Box_Wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 1105px;
    padding-left: 40px;
    padding-right: 40px;
    margin: 86px auto 0;
}
.partners_Logo_Box {
    width: 250px;
    height: 142px;
    background: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.partners_Logo_Box img{max-width: 80%; width: auto; height: auto}
@keyframes spin_3 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

@media (max-width: 1680px) {
    .partners_Box {
        padding: calc(150 / 1680 * 100vw) 0 0;

    }
    .partners-icon3{
        margin-top: calc(79 / 1680 * 100vw);
        margin-left: calc(303 / 1680 * 100vw);
    }
    .partners-icon2{
        margin-top: calc(250 / 1680 * 100vw);
        margin-left: calc(-314 / 1680 * 100vw);
    }
    .partners-icon4{
        margin-top: calc(490 / 1680 * 100vw);
        margin-left: calc(450 / 1680 * 100vw);
    }
    .partners_Title {
        font-size: calc(50 / 1680 * 100vw);
    }
    .partners_Logo_Box_Wrapper {
        max-width: calc(1105 / 1680 * 100vw);
        padding-left: calc(40 / 1680 * 100vw);
        padding-right: calc(40 / 1680 * 100vw);
        margin: calc(86 / 1680 * 100vw) auto 0;
    }
    .partners_Logo_Box {
        width: calc(250 / 1680 * 100vw);
        height: calc(142 / 1680 * 100vw);
    }
}
@media (min-width: 1001px) {

}
@media (max-width: 1000px) {
    .partners_Title {
        font-size: 30px;
    }
    .partners_Logo_Box_Wrapper {
        max-width: none;
        padding-left: 0;
        padding-right: 0;
        margin: 0;
        display: block;
    }
    .partners_Logo_Box {
        height: 142px;
        display: flex !important;
        width: calc(100% - 10px);
    }
    .partners_Logo_Box_Wrapper {
        max-width: 295px;
        margin: 22px auto 0 auto;
    }
    .partners_Logo_Box_Wrapper .slick-list {
        overflow: visible;
    }
    .partners_Logo_Box img {
        max-width: 90%;
    }
    .partners_Logo_Box__out {
        display: block !important;
    }



}
