.timer_Box {
  width: 578px;
  height: 100px;
  background: linear-gradient(245.5deg, #842d70 16.89%, #0e83f0 93.78%);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
}
.eligibility_Footer_Box .timer_Box {
  background: none;
}

.timer_Item_First_Block {
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  text-transform: none;
  text-align: center;
  color: #ffffff;
}
.eligibility_Footer_Box .timer_Item_First_Block {
  display: none;
}
.timer_Item_Last_Block > div{
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 230px;
}

.tim_Item {
  display: inline-flex;
  align-items: center;
  padding-bottom: 20px;
  text-transform: none;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  color: #ffffff;
  position: relative;
}
.tim_Item .item {
  font-style: normal;
  font-weight: 200;
  font-size: 15px;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.tim_Item:not(:last-child):after {
  content: ':';
}
.tim_Item:not(:last-child) .item {
  margin-left: -6px;
}
.timer_Box:not(.started) .timer_Item_Last_Block {
  opacity: 0;
}
@media (max-width: 1680px) {
  .timer_Box {
    width: calc(578 / 1680 * 100vw);
    height: calc(100 / 1680 * 100vw);
  }

  .timer_Item_First_Block {
    font-size: calc(16 / 1680 * 100vw);
  }
  .tim_Item .item {
    font-size: calc(15 / 1680 * 100vw);
  }
  .tim_Item:not(:last-child) .item {
    margin-left: calc(-6 / 1680 * 100vw);
  }

  .tim_Item {
    font-size: calc(40 / 1680 * 100vw);
    padding-bottom: calc(20 / 1680 * 100vw);
  }

  .item {
    font-size: calc(15 / 1680 * 100vw);
    padding: 0 calc(8 / 1680 * 100vw);
  }
}
@media (max-width: 1680px) {
  .eligibility_Footer_Box .tim_Item {
    padding-bottom: 10px;
  }
}
