.lk{
    background: #000;
    position: relative;
    overflow: hidden;
}

.lk-in{
    max-width: 1105px;
    margin: 0 auto;
    position: relative;
    z-index: 3;
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
}

.lk-head{
    background: #fff;
    border-radius: 5px;
    height: 148px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    margin-bottom: 40px;
}

.lk-head-name{
    font-weight: 800;
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 3px;
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lk-head-edit-lnk{
    font-size: 16px;
    line-height: 20px;
    text-decoration: underline;
    cursor: pointer;
}

.lk-head-label{
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 3px;
}

.lk-head-val{
    font-size: 30px;
    line-height: 37px;
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lk-center{
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.lk-center > div{
    width: 48%;
    position: relative;
}

.lk-center > div:last-child{
    display: flex;
    align-items: center;
    justify-content: center;
}

.lk-center > div:last-child .lk-button{
    position: relative;
    z-index: 2;
}

.lk-wheel-title{
    position: relative;
    z-index: 2;
    color: #fff;
    font-weight: 800;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 44px;
}

.lk-center-bg{
    position: absolute;
    width: calc(111.2%);
    height: calc(128%);
    left: -5.8%;
    top: -14%;
}

.lk-center-wheel{
    display: block;
    margin-left: 20px;
}

.lk-counter{
    height: 217px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 58px;
    position: relative;
    z-index: 2;
}

.lk-counter-in{
    display: flex;
}

.lk-counter-in2{
    display: flex;
}

.lk-counter-in2 .lk-button{
    display: inline-flex;
}

.lk-center-tv{
    margin-left: 20px;
    display: block;
}

.lk-counter-title{
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    color: #fff;
    margin-bottom: 15px;
}

.lk-counter-lnk{
    font-size: 14px;
    line-height: 17px;
    color: #00bdf2;
    text-decoration: underline;
    cursor: pointer;
}

.lk-countdown{
    display: flex;
    margin-bottom: 15px;
}

.lk-countdown > div{
    min-width: 50px;
    padding-bottom: 20px;
    font-size: 15px;
    line-height: 18px;
    color: #fff;
    text-align: center;
    position: relative;
}
.lk-countdown > div > span{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.lk-countdown > div > div{
    font-weight: 800;
    font-size: 40px;
    line-height: 49px;
}

.lk-countdown > div.lk-countdown-sep{
    min-width: 0;
    font-size: 40px;
    line-height: 49px;
    font-weight: 200;
    position: relative;
    top: -5px;
    margin: 0 4px;
}

.lk-countdown > div:not(.lk-countdown-sep){
    position: relative;
}

.lk-history{
    padding: 40px;
    background: linear-gradient(245.5deg, #842D70 16.89%, #0E83F0 93.78%);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 84px;
}

.lk-history > div{
    width: 50%;
}

.lk-history-title{
    color: #fff;
    font-weight: 800;
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 6px;
}

.lk-history-count{
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    color: #fff;
    margin-bottom: 1px;
}

.lk-history-count > div{
    font-weight: 200;
    font-size: 100px;
    line-height: 123px;
    margin-right: 31px;
}

.lk-history-dsc{
    color: #fff;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 38px;
}
.lk-history-buttons + .lk-history-dsc{
    margin-top: 38px;
    margin-bottom: 0;
}

.lk-history-buttons{
    display: flex;
    align-items: center;
}

.lk-history-buttons .input{
    margin-bottom: 0;
    margin-right: 20px;
}

.lk-button{
    display: flex;
    height: 60px;
    padding: 0 40px;
    background: #00bdf2;
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    align-items: center;
    cursor: pointer;
    transition: 300ms opacity;
}

.lk-button.disabled{
    background: rgba(0, 189, 242, 0.5);
    color: rgba(255,255,255,0.5);
}

.lk-button-alert{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: #000;
    cursor: pointer;
    margin-left: 10px;
    position: relative;
}
.lk-button-alert span{
    background: rgba(255,255,255,0.7);
    padding: 4px 12px;
    white-space: nowrap;
    text-transform: none;
    text-align: center;
    font-size: 12px;
    color: #000000;
    position: absolute;
    right: 50%;
    bottom: 100%;
    margin-bottom: 3px;
    z-index: 2;
    display: none;
}
.lk-button-alert:hover span{
    display: block;
}
.lk-button.disabled .lk-button-alert{
    background: rgba(255,255,255,0.5);
    color: rgba(0,0,0,0.5);
}

.lk-history-list-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 37px;
}

.lk-history-list-title{
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    color: #fff;
}

.lk-history-list-lnk{
    font-size: 14px;
    line-height: 17px;
    text-decoration: underline;
    color: #fff;
    cursor: pointer;
}

.lk-history-item{
    display: flex;
    border-bottom: 1px solid #fff;
    padding: 17px 0;
    color: #fff;
    align-items: center;
    margin-right: 44px;
}

.lk-history-item:first-child{
    padding-top: 0;
}

.lk-history-item > div{
    font-size: 12px;
    text-align: center;
}

.lk-history-item > div:first-child,
.lk-history-item > div:last-child{
    font-size: 14px;
}

.lk-history-item > div:first-child{
    text-align: left;
}

.lk-history-item > div:last-child{
    text-align: right;
}

.lk-history-item > div:nth-child(1){
    width: 30%;
}

.lk-history-item > div:nth-child(2){
    width: 20%;
}

.lk-history-item > div:nth-child(3){
    width: 30%;
}

.lk-history-item > div:nth-child(4){
    width: 20%;
}

.lk-history-area{
    max-height: 222px;
}

.lk-history-area .scrollbar-container, .simplebar-track.simplebar-vertical{
    width: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3) !important;
    opacity: 1 !important;
    overflow: visible !important;
}

.simplebar-track.simplebar-horizontal{
    height: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3) !important;
    opacity: 1 !important;
    overflow: visible !important;
}
.lk-history-area .scrollbar-container:hover{
    opacity: 1 !important;
}

.lk-history-area .scrollbar-container .scrollbar, .simplebar-scrollbar{
    width: 10px;
    border-radius: 10px;
    background: #fff !important;
    box-shadow: 0px 0px 15px 0px rgba(0,189,242,0.75);
    cursor: pointer;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar{
    height: 10px !important;
    border-radius: 10px;
    top: 0 !important;
    background: #fff !important;
    box-shadow: 0px 0px 15px 0px rgba(0,189,242,0.75) !important;
    cursor: pointer;
}
.simplebar-scrollbar.simplebar-visible:before{display: none}

.lk-prize-title{
    color: #fff;
    margin-bottom: 35px;
    font-weight: 800;
    font-size: 60px;
    line-height: 74px;
    text-align: center;
    text-transform: uppercase;
}

.lk-prize-list-item{
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;
    border-radius: 5px;
    margin-bottom: 10px;
    background: #fff;
    min-height: 83px;
    font-size: 18px;
    /*margin-right: 30px;*/
}

.lk-prize-list-item:last-child{
    margin-bottom: 0;
}

.lk-prize-list-item span{
    font-size: 14px;
    line-height: 17px;
    color: #00bdf2;
    display: block;
    margin-top: 5px;
}

.lk-prize-list-item > div{
    width: 25%;
    box-sizing: border-box;
}

.lk-prize-list-item-head{
    background: #00bdf2;
    color: #fff;
    font-size: 14px;
}

.lk-history-area-prize{
    max-height: 457px;
    margin-bottom: 151px;
}
.lk-history-area-prize .simplebar-track.simplebar-vertical{
    transform: translateX(25px);
}

@keyframes spin1 {
    0%  {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}

@keyframes spin2 {
    0%  {transform: rotate(0deg);}
    100% {transform: rotate(-360deg);}
}

@keyframes spin3 {
    0%  {transform: rotate(0deg);}
    33% {transform: rotate(1deg);}
    66% {transform: rotate(-1deg);}
    100% {transform: rotate(0deg);}
}

@keyframes spin4 {
    0%  {transform: rotate(0deg);}
    33% {transform: rotate(-1deg);}
    66% {transform: rotate(1deg);}
    100% {transform: rotate(0deg);}
}

.lk_el1{
    position: absolute;
    left: 113px;
    top: 218px;
    animation: spin2 40s infinite linear;
    z-index: 2;
}

.lk_el2{
    position: absolute;
    right: 198px;
    top: 385px;
    animation: spin1 25s infinite linear;
    z-index: 4;
}

.lk_el3{
    position: absolute;
    left: -200px;
    top: 308px;
    animation: spin1 100s infinite linear;
    z-index: 2;
}

.lk_el4{
    position: absolute;
    right: -200px;
    top: 550px;
    animation: spin2 80s infinite linear;
    z-index: 2;
}

.lk_el5{
    position: absolute;
    left: 357px;
    top: 1130px;
    animation: spin2 30s infinite linear;
    z-index: 2;
}

.lk_el6{
    position: absolute;
    left: -5%;
    bottom: 400px;
    width: 110%;
    animation: spin3 10s infinite linear;
    z-index: 2;
}

.lk_el7{
    position: absolute;
    left: -5%;
    bottom: 300px;
    width: 110%;
    animation: spin4 15s infinite linear;
    z-index: 2;
}

.lk_el8{
    position: absolute;
    width: 1023px;
    height: 368px;
    left: -218px;
    top: 980px;
    background: #ff54c4;
    filter: blur(200px);
    z-index: 1;
    transform: translateZ(0);
}

.lk_el9{
    position: absolute;
    width: 918px;
    height: 510px;
    right: -200px;
    bottom: 0;
    background: #00bdf2;
    filter: blur(200px);
    z-index: 1;
    transform: translateZ(0);
}

.lk_el10{
    position: absolute;
    right: 198px;
    bottom: 700px;
    animation: spin2 40s infinite linear;
    z-index: 2;
}

.lk_el11{
    position: absolute;
    left: 113px;
    bottom: 500px;
    animation: spin1 25s infinite linear;
    z-index: 2;
}

.lk-prize-list-item-lnk{
    cursor: pointer;
}

@media (min-width: 1000px) {
    .lk-head-edit-lnk:hover{
        text-decoration: none;
    }

    .lk-counter-lnk:hover{
        text-decoration: none;
    }

    .lk-button:hover{
        text-decoration: underline;
    }

    .lk-history-list-lnk:hover{
        text-decoration: none;
    }

    .lk-prize-list-item-lnk:hover{
        text-decoration: underline;
    }
}

@media (max-width: 1000px) {
    .lk{overflow: hidden}
    .lk-in{
        padding: 40px 40px 82px 40px;
    }

    .lk-head{
        margin-bottom: 30px;
        padding: 40px;
        display: block;
        height: auto;
    }

    .lk-head > div{
        margin-bottom: 30px;
    }

    .lk-head > div:last-child{
        margin-bottom: 0;
    }

    .lk-head-name{
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 11px;
        max-width: 100%;
    }

    .lk-head-edit-lnk{
        font-size: 14px;
        line-height: 17px;
    }

    .lk-head-label{
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 3px;
    }

    .lk-head-val{
        font-size: 20px;
        line-height: 25px;
        max-width: 100%;
    }

    .lk-center{
        display: block;
    }

    .lk-center > div{
        width: auto;
        margin-bottom: 30px;
    }

    .lk-counter{
        height: auto;
    }

    .lk-prize-title{
        font-size: 30px;
        line-height: 37px;
        margin-bottom: 22px;
    }

    .lk-prize-list-item-mobile{
        margin-bottom: 10px;
        border-radius: 5px;
        overflow: hidden;
    }

    .lk-prize-list-item-mobile:last-child{
        margin-bottom: 0;
    }

    .lk-prize-list-item-mobile-title{
        padding: 12px 9px;
        background: #00bdf2;
        font-size: 14px;
        line-height: 1.2;
        text-align: center;
        color: #fff;
    }

    .lk-prize-list-item-mobile-content{
        padding: 23px 16px;
        background: #fff;
        font-size: 12px;
        line-height: 15px;
    }

    .lk-prize-list-item-mobile-content > div{
        display: flex;
        margin-bottom: 11px;
    }

    .lk-prize-list-item-mobile-content > div:last-child{
        margin-bottom: 0;
    }

    .lk-prize-list-item-mobile-content > div > div:first-child{
        width: 40%;
        color: #00bdf2;
    }

    .lk-prize-list-item-mobile-content a,
    .lk-prize-list-item-mobile-content span{
        color: #FF54C4;
        text-transform: uppercase;
        text-decoration: underline;
    }

    .lk-history{
        display: block;
        padding: 34px;
        margin-bottom: 73px;
    }

    .lk-history > div{
        width: auto;
    }

    .lk-history-title{
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 20px;
    }

    .lk-history-count-mobile{
        color: #fff;
        display: flex;
        align-items: center;
        margin-bottom: 11px;
    }

    .lk-history-count-mobile > div:first-child{
        font-size: 75px;
        line-height: 92px;
        font-weight: 100;
        margin-right: 12px;
    }

    .lk-history-count-mobile > div:last-child{
        font-size: 14px;
        line-height: 17px;
    }

    .lk-history-count-mobile > div:last-child div{
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 6px;
    }

    .lk-history-buttons + .lk-history-dsc{
        margin-bottom: 38px;
    }

    .lk-history-buttons{
        display: block;
    }

    .lk-history-buttons .input{
        margin-right: 0;
        margin-bottom: 10px;
    }

    .lk-button{
        padding: 0;
        justify-content: center;
    }

    .lk-history-buttons .lk-button{
        margin-bottom: 37px;
    }

    .lk-history-list-head{
        margin-bottom: 28px;
        display: block;
    }

    .lk-history-list-title{
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 1px;
    }

    .lk-history-list-lnk{
        font-size: 14px;
        line-height: 17px;
    }

    .lk-history-item{
        border: 2px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 5px;
        margin-right: 0;
        margin-bottom: 10px;
        padding: 20px;
        font-size: 12px;
        line-height: 15px;
        display: block;
    }

    .lk-history-item > div{
        width: auto !important;
        text-align: left !important;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 5px;
    }

    .lk-history-item:last-child{
        margin-bottom: 0;
    }

    .lk-counter{
        padding: 32px;
    }

    .lk-counter-in{
        flex-direction: column;
    }

    .lk-counter-in > div:first-child{
        order: 1;
    }

    .lk-center-tv{
        display: block;
        margin: 0 auto 12px auto;
    }

    .lk-counter-title{
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 9px;
    }

    .lk-countdown-sep{
        font-size: 25px !important;
        line-height: 31px !important;
        top: -2px !important;
    }

    .lk-countdown > div > div{
        font-size: 25px;
        line-height: 31px;
    }

    .lk-counter-in2{
        padding: 42px;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
    }

    .lk-counter-in2 > div{
        width: 100%;
    }

    .lk-counter-in2 > div:first-child{
        order: 1;
    }

    .lk-center-wheel{
        display: block;
        margin: 0 auto 9px auto;
    }

    .lk-wheel-title{
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 27px;
    }

    .lk-counter-in2 .lk-button{
        display: flex;
    }

    .lk-center{
        margin-bottom: 30px;
    }

    .lk_el9{
        top: 150px;
        left: -250px;
        width: 477px;
        height: 203px;
        filter: blur(70px);
    }

    .lk_el3{
        height: auto;
        left: auto;
        right: -130px;
        top: 50px;
    }

    .lk_el4{
        height: auto;
        right: auto;
        left: -130px;
        bottom: 770px;
        top: auto;
    }

    .lk_el5{
        right: auto;
        left: 20px;
        top: auto;
        bottom: 250px;
        width: 35px;
        height: auto;
    }

    .lk_el1{
        right: 20px;
        left: auto;
        top: auto;
        bottom: 850px;
        width: 40px;
        height: auto;
    }

    .lk_el2{
        right: 0px;
        left: auto;
        top: auto;
        bottom: 650px;
        width: 35px;
        height: auto;
    }

    .lk-center-bg{
        position: absolute;
        width: calc(120%);
        height: calc(120%);
        left: -10%;
        top: -10%;
    }

    .lk_el6{
        width: 250%;
        display: none;
    }

    .lk_el7{
        width: 250%;
        display: none;
    }

    .lk_el8{
        width: 565px;
        height: 203px;
        filter: blur(80px);
        top: auto;
        top: 1200px;
        left: -250px;
    }


}

@media (max-width: 320px) {
    .lk-in{
        padding-left: 20px;
        padding-right: 20px;
    }
}
