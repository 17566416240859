.winners {
    position: relative;
}
.winners__bg{
    content: '';
    position: absolute;
    top: 50%; left: 50%;
    width: 100%;
    transform: translate(-50%, -40%);
    background-image: url(assets/bg.png?2);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: 1928px;
    /*max-height: 200%;*/
    background-size: auto 100%;
    overflow: hidden;
}
.winners__content{
    max-width: 1106px;
    padding-left: 40px; padding-right: 40px;
    margin: 0 auto;
    position: relative;
}
.winners__btn{text-align: center; padding-top: 50px;}
.winners__title {
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 74px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 75px;
}
.winners__list__item {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;
    border-radius: 5px;
    margin-bottom: 10px;
    background: #fff;
    min-height: 83px;
    font-size: 18px;
}
@media (min-width: 1000px) {
    .winners__list__item > div:nth-child(1) {
        width: 5%;
        box-sizing: border-box;
    }
    .winners__list__item > div {
        width: 15%;
        box-sizing: border-box;
    }
    .winners__list__item > div:nth-child(2){
        width: 40%;
    }
    .winners__list__item > div:nth-child(3){
        width: 20%;
    }
}
.winners__list__item--header {
    background: #00bdf2;
    color: #fff;
    font-size: 14px;
}
.winner__list__img{
    display: flex;
    align-items: center;
}
.winner__list__img img{
    max-width: 100%; max-height: 100%;
}
.winner__list__img > span:first-child{
    /*width: 80px; height: 80px;*/
    /*text-align: center;*/
    line-height: 1;
    padding: 10px;
    /*line-height: 80px;*/
}
.winners__weeks__item{
    font-size: 14px;
    color: #fff;
    display: flex;
}
.winners__weeks__item:not([data-disabled="true"]) {
    cursor: pointer;
}
.winners__weeks__item__icon{margin-right: 8px; opacity: 0; transition: 300ms;}
.winners__weeks__item__title{
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 10px;
}
.winners__weeks__item[data-active="true"]:not([data-disabled="true"]) .winners__weeks__item__title{
    color: #00BDF2;
}
.winners__weeks__item[data-active="true"]:not([data-disabled="true"]) .winners__weeks__item__icon{display: inline-block; opacity: 1}
.winners__weeks{
    padding-left: 70px;
    padding-right: 70px;
    max-width: 930px;
    box-sizing: border-box;
    margin: 0 auto 77px;
}
@media (min-width: 1001px) {
    .winners__weeks__item:not([data-disabled="true"]):hover .winners__weeks__item__title{
        color: #00BDF2;
    }
}

.slick-arrow{
    position: absolute;
    top: 50%;
    background-position: 0 0; background-repeat: no-repeat;
    background-size: contain;
    width: 104px; height: 103px;
    transition: 300ms;
    cursor: pointer;
}
.slick-arrow svg{
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    width: 54px; height: 55px;
}
.slick-prev{
    left: -70px;
    background-image: url("assets/shadow-prev.png?2");
    transform: translate(-20px, -50%);
}
.slick-prev svg{margin-left: -10px;}
.slick-next{
    right: -70px;
    background-image: url("assets/shadow-next.png?2");
    transform: translate(20px, -50%);
}
.slick-arrow.slick-disabled{
    background: none;
    opacity: .5;
    cursor: default;
}

.winners-icon2{
    margin-top: 343px; margin-left: -670px;
}
.winners-icon4{
    margin-top: 62px; margin-left: 117px;
}


@media (max-width: 1680px) {
    .winners__bg{
        height: calc(1928 / 1680 * 100vw);
    }
    .winners__btn{padding-top: calc(50 / 1680 * 100vw);}
    .winners__title {
        font-size: calc(50 / 1680 * 100vw);
        line-height: calc(74 / 1680 * 100vw);
        margin-bottom: calc(75 / 1680 * 100vw);
    }
    .winners__list__item {
        padding: 0 calc(60 / 1680 * 100vw);
        margin-bottom: calc(10 / 1680 * 100vw);
        min-height: calc(83 / 1680 * 100vw);
        font-size: calc(18 / 1680 * 100vw);
    }
    .winners__list__item--header {
        font-size: calc(14 / 1680 * 100vw);
    }
    .winner__list__img > span:first-child{
        /*width: calc(80 / 1680 * 100vw); height: calc(80 / 1680 * 100vw);*/
        /*line-height: calc(80 / 1680 * 100vw);*/
    }
    .winners__weeks__item{
        font-size: calc(14 / 1680 * 100vw);
    }
    .winners__weeks__item__icon{margin-right:calc( 8 / 1680 * 100vw);}
    .winners__weeks__item__title{
        font-size: calc(24 / 1680 * 100vw);
        margin-bottom: calc(10 / 1680 * 100vw);
    }
    .winners__weeks{
        padding-left: calc(70 / 1680 * 100vw);
        padding-right: calc(70 / 1680 * 100vw);
        max-width: calc(930 / 1680 * 100vw);
        margin: 0 auto calc(77 / 1680 * 100vw);
    }

    .slick-arrow{
        width: calc(104 / 1680 * 100vw); height: calc(103 / 1680 * 100vw);
    }
    .slick-prev{
        left: calc(-70 / 1680 * 100vw);
        transform: translate(calc(-20 / 1680 * 100vw), -50%);
    }
    .slick-prev svg{margin-left: calc(-10 / 1680 * 100vw);}
    .slick-next{
        right: calc(-70 / 1680 * 100vw);
        transform: translate(calc(20 / 1680 * 100vw), -50%);
    }
    .slick-arrow svg{
        width: calc(54 / 1680 * 100vw); height: calc(55 / 1680 * 100vw);
    }
    .winners-icon2{
        margin-top: calc(343 / 1680 * 100vw); margin-left: calc(-670 / 1680 * 100vw);
    }
    .winners-icon4{
        margin-top: calc(62 / 1680 * 100vw); margin-left: calc(117 / 1680 * 100vw);
    }
    .winners__content{
        max-width: calc(1160 / 1680 * 100vw);
    }

}

@media (max-width: 1000px) {
    .winners__title {
        font-size: 30px;
        line-height: 37px;
        margin-bottom: 0;
    }

    .winners__content {
        max-width: none;
    }
    .winners__content {
        padding-left: 30px;
        padding-right: 30px;
    }
    .winners__weeks {
        padding-left: 0;
        padding-right: 0;
        max-width: none;
        margin: 23px 0 23px 0;
    }

    .winners__weeks__item__title {
        font-size: 24px;
        margin-bottom: 0;
        padding-left: 0;
    }
    .winners__weeks__item {
        font-size: 14px;
        justify-content: center;
    }
    .winners__weeks__item span {
        padding-left: 3px;
    }
    .slick-arrow svg {
        display: none;
    }
    .slick-arrow {
        width: 45px;
        height: 56px;
        outline: none;
        font-size: 0;
        border: none;
    }

    .slick-prev {
        left: -10px;
        transform: none;
        margin-top: -28px;
        z-index: 9999;
        background: url("assets/arr_l.png?2") center center no-repeat;
        background-size: contain;
    }
    .slick-next {
        right: -10px;
        transform: none;
        margin-top: -28px;
        z-index: 9999;
        background: url("assets/arr_r.png?2") center center no-repeat;
        background-size: contain;
    }

    .slick-prev.slick-disabled {
        background-image: url("assets/arr_l_dis.png?2");
    }
    .slick-next.slick-disabled {
        background-image: url("assets/arr_r_dis.png?2");
    }
    .winners__list__item {
        padding: 0;
        margin-bottom: 10px;
        min-height: auto;
        font-size: 12px;
        display: block;
    }
    .winners__list__item__block {
        display: flex;
        margin-bottom: 10px;
    }
    .winners__list__item__block:last-child {
        margin-bottom: 0;
    }
    .w_title {
        width: 90px;
        padding-right: 10px;
        color: #00BDF2;
    }
    .w_value {
        color: #000;
    }
    .winners__list__item > div {
        width: auto;
        box-sizing: border-box;
    }
    .winners__list__item__in {
        padding: 16px 28px 19px 23px;

    }
    .winners__list__item__title {
        color: #fff;
        text-align: center;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        background: #00BDF2;
        width: 100% !important;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .page-btn.trans {
        display: block;
        background: transparent;
        font-weight: normal;
    }
}

