@keyframes popup {
  0%  {opacity: 0;}
  100% {opacity: 1;}
}

body.show-popup{
  overflow: hidden;
}

.popup{
  position: fixed;
  z-index: 9999999999;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: popup 300ms linear forwards;
  opacity: 0;
}

.popup-bg{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.popup-in{
  width: 588px;
  box-sizing: border-box;
  padding: 100px;
  background: linear-gradient(245.5deg, #842D70 16.89%, #0E83F0 93.78%);
  border-radius: 5px;
  position: relative;
  z-index: 2;
}

.popup-in-prize{
  width: 806px;
}

.popup-title{
  color: #fff;
  font-weight: 800;
  font-size: 36px;
  margin-bottom: 30px;
}

.popup-close{
  width: 80px;
  height: 80px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms transform;
}

.lk-button{
  text-decoration: none !important;
}
.lk-button span{
  border-bottom: 1px solid transparent;
  transition: 300ms;
}
@media (min-width: 1000px) {
  .lk-button:hover span{border-bottom-color: #fff;}
}
.popup .lk-button{
  padding: 0;
  max-width: 250px;
  justify-content: center;
}

.popup-prize-plan{
  display: block;
  margin-bottom: 30px;
  max-width: 100%;
}

.popup-prize-dsc{
  color: #fff;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 200;
}

.input{
  margin-bottom: 30px;
  box-sizing: border-box;
  height: 60px;
  position: relative;
}

.input:before{
  content: '';
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #FFFFFF;
  z-index: 2;
  position: absolute;
  border-radius: 5px;
}

.input:after{
  content: '';
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  border: 10px solid #00bdf2;
  box-sizing: border-box;
  filter: blur(15px);
  border-radius: 5px;
  position: absolute;
}

.input input{
  position: relative;
  z-index: 2;
  font-size: 18px;
  border: none;
  outline: none;
  background: none;
  width: 100%;
  height: 100%;
  color: #fff;
  font-family:"AvenirNextCyr";
  padding: 0 22px;
  color: #fff;
  box-sizing: border-box;
}

.input input::placeholder{
  color: #fff;
}

.popup-edit-dsc{
  color: #fff;
  font-size: 14px;
  margin-bottom: 30px;
}
.popup-edit-dsc2{
  color: #fff;
  font-size: 14px;
  margin-top: 30px;
}
.popup-edit-dsc2 span{
  border-bottom: 1px solid;
  cursor: pointer;
}
.popup-dsc{
  margin-bottom: 30px;
  font-size: 24px;
  line-height: 1;
  font-weight: 800;
  color: #ffd200;
}

.popup-in-schedule {
  width: auto;
}
.popup-schedule-table {
  white-space: nowrap;
}
.popup-schedule-table table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.popup-schedule-table:not(.fitted) table {
  width: calc(100% - 30px);
}
.popup-schedule-table th, .popup-schedule-table td {
  border-bottom: 1px solid #ffffff;
  text-align: left;
  line-height: 15px;
  font-weight: 200;
  font-size: 14px;
}
.popup-schedule-table th:not(:first-child), .popup-schedule-table td:not(:first-child) {
  padding-left: 5px;
}
.popup-schedule-table th:not(:last-child), .popup-schedule-table td:not(:last-child) {
  padding-right: 5px;
}
.popup-schedule-table th {
  padding-bottom: 15px;
  color: #00bdf2;
}
.popup-schedule-table td {
  height: 49px;
  color: #ffffff;
}
.popup-schedule-table + .page-btn {
  margin-top: 30px;
}
.popup-schedule-status {
  display: inline-flex;
  align-items: center;
}
.popup-schedule-status:before {
  content: '';
  flex-shrink: 0;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 8px;
}
.popup-schedule-status.schedule-status1:before {
  background: #000000;
}
.popup-schedule-status.schedule-status2:before {
  background: #00bdf2;
}
.popup-schedule-status.schedule-status3:before {
  background: #ff54c4;
}

@media (min-width: 1000px) {
  .popup-close:hover{
    transform: scale(0.9);
  }

  .popup-schedule-table td br {
    display: none;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
@media (max-width: 1000px) {
  .popup{
    overflow-x: hidden;
    overflow-y: auto;
  }

  .popup-in{
    padding: 40px;
  }

  .popup-title{
    font-size: 24px;
    line-height: 1;
    margin-bottom: 30px;
  }

  .popup-in .lk-button{
    max-width: none;
  }

  .popup-dsc{
    font-size: 20px;
  }

  .popup-in-schedule {
    width: 90%;
    padding: 30px 40px 30px 20px;
  }
  .popup-in-schedule .popup-title{
    font-size: 20px;
  }
  .popup-schedule-table{
    margin-bottom: 20px;
  }
  .popup-schedule-table .simplebar-track.simplebar-vertical{
    transform: translateX(30px);
  }
  .popup-schedule-table .simplebar-track.simplebar-horizontal{
    transform: translateY(30px);
  }
}