.wheel{
  position: relative;
  overflow: hidden;
  background: #000;
}

.wheel-in{
  padding-top: 90px;
  position: relative;
  z-index: 3;
  padding-bottom: 151px;
}

.wheel-title{
  font-weight: 800;
  font-size: 60px;
  line-height: 74px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 27px;
}

.wheel-head{
  display: flex;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  margin:0 auto 68px auto;
  max-width: 1180px;
}

.wheel-head > div{
  width: 30%;
}

.wheel-head-item{
  width: 277px;
  height: 277px;
  font-size: 100px;
  font-weight: 100;
  color: #fff;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
}

.wheel-head-item div{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wheel-head-item img{
  display: block;
  position: relative;
  z-index: 2;
}

.wheel-head-item-img1{
  animation: spin2 80s infinite linear;
}

.wheel-head-item-img2{
  animation: spin1 80s infinite linear;
}

.wheel-head-item-img3{
  animation: spin2 80s infinite linear;
}

.wheel-title2{
  font-size: 70px;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0px 0px 20px #00bdf2, 0px 0px 20px #00bdf2;
  font-weight: 100;
}

.wheel-box{
  position: relative;
  width: 1157px;
  height: 578px;
  overflow: hidden;
  margin:0 auto 50px auto;
  border-bottom: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  margin-left: -578px;
}

.wheel-box-el{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.wheel-box-el.active{
  animation: spin1 4s 1 ease-in-out;
}

.wheel-box-arrow{
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -106px;
  z-index: 2;
}

.wheel-button-wrap{
  display: flex;
  justify-content: center;
  margin:0 auto 98px auto;
}

.wheel-dsc{
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 1.2;
  max-width: 1157px;
  margin: 0 auto;
  color: #fff;
}

.wheel-dsc > div{
  width: 50%;
}

.wheel-dsc a{
  color: #00bdf2;
}

.wheel-label{
  background: linear-gradient(245.5deg, #842D70 16.89%, #0E83F0 93.78%);
  border-radius: 5px;
  padding: 45px;
  max-width: 1157px;
  margin: 0 auto;
  color: #fff;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
}

.wheel-label-title{
  font-weight: 200;
  font-size: 30px;
  line-height: 37px;
  text-transform: uppercase;
  margin-bottom: 26px;
}

.wheel-label a{
  color: #00bdf2;
}

.wheel-box-el-empty{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.wheel-box-category-img{
  display: block;
  margin: 0 auto 22px auto;
  max-width: 200px;
  max-height: 100px;
}

.wheel-box-category-text{
  text-align: center;
  font-weight: 800;
  font-size: 32px;
  line-height: 1.2;
  color: #fff;
  margin-bottom: 37px;
}

.wheel-box-category-lnk{
  text-align: center;
}

.wheel-box-category-lnk a{
  color: #00bdf2;
  text-decoration: underline;
  cursor: pointer;
}

.wheel-box-category-wrap{
  padding-top: 140px;
  width: 700px;
  max-width: 90vw;
}

.wheel-box-hide-element{
  transition: 300ms opacity, 300ms visibility;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}

.wheel-box-visible-element{
  transition: 300ms opacity, 300ms visibility;
}

.wheel-box.active .wheel-box-hide-element{
  opacity: 1;
  visibility: visible;
}

.wheel-box.active .wheel-box-visible-element{
  opacity: 0;
  visibility: hidden;
}

@media (min-width: 1000px) {
  .wheel-dsc a:hover,
  .wheel-label a:hover,
  .wheel-box-category-lnk a:hover{
    text-decoration: none;
  }
}

@media (max-width: 1300px) {
  .wheel .lk_el3{
    left: -400px;
  }

  .wheel .lk_el4{
    right: -400px;
  }
}

@media (max-width: 1000px) {
  .wheel-head{
    margin-bottom: 45px;
  }

  .wheel-head > div{
    width: 100%;
  }

  .wheel-head .rec-slider-container{
    margin: 0;
  }

  .wheel-head-item-wrap-mobile{
    padding: 0 40px;
  }

  .wheel-head-item-wrap-mobile .wheel-head-item{
    width: auto;
  }

  .wheel-head .rec-pagination{
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .wheel-head .rec-pagination button{
    box-sizing: border-box;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
    box-shadow: none;
    background: none;
    border: 2px solid #fff;
    transition: 300ms background, 300ms border-color;
  }

  .wheel-head .rec-pagination button.rec-dot_active{
    background: #00bdf2;
    border-color: #00bdf2;
  }

  .wheel-title{
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 5px;
  }

  .wheel-in{
    padding: 50px 0 0 0;
  }

  .wheel-title2{
    font-size: 25px;
    line-height: 1.2;
    margin-bottom: 10px;
    padding: 0 40px;
  }

  .wheel-title2 br{
    display: none;
  }

  .wheel-box{
    left: 50%;
    margin-left: -470px;
    width: 940px;
    height: 470px;
    margin-bottom: 30px;
  }

  .wheel-box-arrow{
    width: 170px;
    margin-left: -85px;
  }

  .wheel-button-wrap{
    display: block;
    padding: 0 40px;
    margin-bottom: 50px;
  }

  .wheel-dsc{
    display: block;
    padding: 0 40px;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 80px;
  }

  .wheel-dsc > div{
    width: auto;
    margin-bottom: 22px;
  }

  .wheel-label{
    padding: 40px;
    text-align: left;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 80px;
  }

  .wheel-label-title{
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 25px;
  }

  .wheel-box-category-img{
    margin-bottom: 0;
    max-width: 200px;
    max-height: 100px;
  }

  .wheel-box-category-text{
    font-size: 20px;
    line-height: 29px;
    padding: 0 40px;
    margin-bottom: 28px;
  }

  .wheel-box-category-lnk{
    font-size: 18px;
    line-height: 22px;
  }

  .wheel-box-category-wrap{
    padding-top: 120px;
  }

  .wheel .lk_el1{
    bottom: auto;
    top: 420px;
  }

  .wheel .lk_el2{
    bottom: auto;
    top: 800px;
    right: auto;
    left: 40px;
  }
}