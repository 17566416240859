* {
  margin: 0;
  padding: 0;
  outline: none;
}

body {
  font-family:"AvenirNextCyr";
  overflow-x: hidden;
  background: #000;
}
input, button { -webkit-appearance: none; -moz-appearance: none; appearance: none; -webkit-box-shadow: none; box-shadow: none; }

@font-face {
  font-family: "AvenirNextCyr";
  src: url(./assets/fonts/AvenirNextCyr-UltraLight.ttf) format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "AvenirNextCyr";
  src: url(./assets/fonts/AvenirNextCyr-Light.ttf) format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "AvenirNextCyr";
  src: url(./assets/fonts/AvenirNextCyr-Heavy.ttf) format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "AvenirNextCyr";
  src: url(./assets/fonts/AvenirNextCyr-Medium.ttf) format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "AvenirNextCyr";
  src: url(./assets/fonts/AvenirNextCyr-Regular.ttf) format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "AvenirNextCyr";
  src: url(./assets/fonts/AvenirNextCyr-BoldItalic.ttf) format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "AvenirNextCyr";
  src: url(./assets/fonts/AvenirNextCyr-Bold.ttf) format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "AvenirNextCyr";
  src: url(./assets/fonts/AvenirNextCyr-Demi.ttf) format("truetype");
  font-weight: 800;
}

.content{
  max-width: 1106px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
}
