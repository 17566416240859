.ellipse-blue{
    position: absolute;
    background: url("assets/ellipse-blue.png?2");
    width: 1582px;
    height: 1310px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
@media (max-width: 1680px) {
    .ellipse-blue{
        width: calc(1582 / 1680 * 100vw);
        height:calc(1310 / 1680 * 100vw);
    }
}
.ellipse-pink{
    position: absolute;
    background: url("assets/ellipse-pink.png?2");
    width: 1643px;
    height: 1310px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.ellipse--1{
    top: 630px;
    left: -600px;
    opacity: .7;
}

.ellipse--2{
    top: calc(975px + 400px);
    right: calc(-275px - 330px);
}


@media (max-width: 1680px) {
    .ellipse-pink{
        width: calc(1643 / 1680 * 100vw);
        height:calc(1310 / 1680 * 100vw);
    }
}