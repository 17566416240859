
.coming_Soon_Box {
  padding: 40px 0 300px;
  position: relative;
}

.comp{
  position: absolute;
  top: 30px; right: -50px;
  background-image: url("assets/comp.png?2");
  width: 887px; height: 877px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.coming_Soon_Timer {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.comin_Soon_Promo {
  font-style: normal;
  font-weight: 200;
  font-size: 30px;
  text-transform: uppercase;
  color: #00bdf2;
  margin-top: 50px;
}

.coming_Soon_Contect {
  font-style: normal;
  font-weight: 100;
  font-size: 65px;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 40px;
}
.comin_Soon_Title {
  font-style: normal;
  font-weight: 400;
  font-size: 100px;
  text-transform: uppercase;
  color: #ffffff;
  position: relative;
}

.coming_Soon_Limit {
  font-style: normal;
  font-weight: 200;
  font-size: 30px;
  text-transform: uppercase;
  color: #ff54c4;
  margin-top: 35px;
}
.page-btn.coming_Soon_Btn {
  margin-top: 90px;
}


@media (max-width: 1680px) {
  .coming_Soon_Box {
    padding: calc(40 / 1680 * 100vw) 0 calc(300 / 1680 * 100vw);
  }
  .comin_Soon_Promo {
    font-size: calc(30 / 1680 * 100vw);
    margin-top: calc(50 / 1680 * 100vw);
  }

  .coming_Soon_Contect {
    font-size: calc(65 / 1680 * 100vw);
    margin-top: calc(40 / 1680 * 100vw);
  }
  .comin_Soon_Title {
    font-size: calc(100 / 1680 * 100vw);
  }

  .coming_Soon_Limit {
    font-size: calc(30 / 1680 * 100vw);
    margin-top: calc(35 / 1680 * 100vw);
  }
  .page-btn.coming_Soon_Btn {
    margin-top: calc(90 / 1680 * 100vw);
  }

  .comp{
    top: calc(30 / 1680 * 100vw); right: calc(-50 / 1680 * 100vw);
    width: calc(887 / 1680 * 100vw); height: calc(877 / 1680 * 100vw);
  }

}

@media (max-width: 1000px) {
  .comp{
    background-image: url("assets/comp_mob.png?2");
  }
}

