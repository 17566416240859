.questions_Item{
    background-color: #fff;
    border-radius: 5px;
    padding: 30px 62px 30px 52px;
    margin-bottom: 10px;
    position: relative;
    z-index: 1000000000;
    cursor: pointer;
}
.questions_Item_Title{
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    color: #00BDF2;
}
.questions_Item_Answer_Context{
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-top: 30px;
}
.questions_Item_Answer_Context a {
    color: #0000ff;
}
.questions_Item_Answer_Context a:hover {
    text-decoration: none;
}
.questions_Item_Toggle{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 1680px) {
    .questions_Item{
        padding: calc(30 / 1680 * 100vw) calc(62 / 1680 * 100vw) calc(30 / 1680 * 100vw) calc(52 / 1680 * 100vw);
    }
    .questions_Item_Title{
        font-size: calc(36 / 1680 * 100vw);
    }
    .questions_Item_Answer_Context{
        font-size: calc(18 / 1680 * 100vw);
        line-height: calc(22 / 1680 * 100vw);
        margin-top: calc(30 / 1680 * 100vw);
    }
}

