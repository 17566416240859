.questions {
    position: relative;
}
.questions__bg{
    position: absolute;
    top: -100px; left: 0; width: 100%; height: calc(100% + 340px);
    overflow: hidden;
    z-index: -1;
}
.questions__pink{
    top: 500px; left: 50%; margin-left: -200px;
    opacity: .7;
}
.questions__blue{
    top: -70px; left: 50%; margin-left: -1600px;
}
.questions__bg:before, .questions__bg:after{
    content: '';
    position: absolute;
    left: 50%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    width: 620px;
    height: 620px;
}
.questions__bg:before{
    animation: spin2 linear 11s infinite;
    background-image: url("../Eligibility/assets/1.png?2");
    top: 50%;
    margin-left: -1004px;
    margin-top: -90px;

}
.questions__bg:after{
    top: 0; right: 0;
    margin-left: 354px;
    margin-top: 190px;
    animation: spin1 linear 11s infinite;
    background-image: url("../Eligibility/assets/2.png?2");
}

.questionsSection_Box {
    padding: 175px 0 150px;
    max-width: 1106px;
    margin: 0 auto;
}
.questionsSection_Box_Title {
    font-size: 60px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 85px;
}
.questions-icon2{
    margin-left: -445px;
    margin-top: 235px;
}
.questions-icon3{
    margin-left: -676px;
    margin-top: 474px;
}
.questions-icon4{
    margin-left: 485px;
    margin-top: 1150px;
}

@media (max-width: 1680px) {
    .questions__bg{
        top: -100px;
        height: calc(100% + 240px + calc(100 / 1680 * 100vw));
    }
    .questions__pink{
        top: calc(500 / 1680 * 100vw);
        margin-left: calc(-200 / 1680 * 100vw);
    }
    .questions__blue{
        top: calc(-70 / 1680 * 100vw);
        margin-left: calc(-1600 / 1680 * 100vw);
    }
    .questions__bg:before, .questions__bg:after{
        width: calc(620 / 1680 * 100vw);
        height: calc(620 / 1680 * 100vw);
    }
    .questions__bg:before{
        margin-left: calc(-1004 / 1680 * 100vw);
        margin-top: calc(-90 / 1680 * 100vw);

    }
    .questions__bg:after{
        margin-left: calc(354 / 1680 * 100vw);
        margin-top: calc(190 / 1680 * 100vw);
    }

    .questionsSection_Box {
        padding: calc(175 / 1680 * 100vw) 0 calc(150 / 1680 * 100vw);
        max-width: calc(1106 / 1680 * 100vw);
    }
    .questionsSection_Box_Title {
        font-size:calc( 60 / 1680 * 100vw);
        margin-bottom:calc( 85 / 1680 * 100vw);
    }
    .questions-icon2{
        margin-left: calc(-445 / 1680 * 100vw);
        margin-top: calc(235 / 1680 * 100vw);
    }
    .questions-icon3{
        margin-left: calc(-676 / 1680 * 100vw);
        margin-top: calc(474 / 1680 * 100vw);
    }
    .questions-icon4{
        margin-left: calc(485 / 1680 * 100vw);
        margin-top: calc(1150 / 1680 * 100vw);
    }
}
.questionsSection_Box .for-desc {
    display: none;
}
.questionsSection_Box .for-mob {
    display: none;

}
@media (min-width: 1000px) {
    .questionsSection_Box .for-desc {
        display: inline-block;
    }
}

@media (max-width: 1000px) {
    .questionsSection_Box .for-mob {
        display: inline-block;
    }
    .questionsSection_Box_Title {
        font-size: 30px;
        margin-bottom: 20px;
        margin-left: -20px;
        margin-right: -20px;
    }
    .questionsSection_Box {
        padding: 0 40px;
        max-width: none;
    }
    .questions {
        padding-top: 20px;
        margin: 52px 0 75px 0;
    }
    .questions_Item_Title {
        font-size: 20px;
        line-height: 24px;
        padding-right: 10px;
    }

    .questions_Item {
        padding: 26px 20px 26px 20px;
    }
    .questions_Item_Toggle img {
        width: 16px;
    }
    .questions_Item_Answer_Context {
        font-size: 14px;
        line-height: 17px;
        margin-top: 19px;
    }
}
