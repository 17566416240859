
.promo_Action_Box {
  max-width: 1300px;
  margin: 0 auto;
}

.promo_Action_Box__title {
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  position: relative;
  z-index: 2;
}
.unit {
  font-weight: 200;
  font-size: 24px;
  text-align: center;
  color: #ffffff;
  margin-top: 29px;
  position: relative;
  z-index: 2;
}



.propmo_Card_Box{
  margin-top: 90px;
  width: 50%;
  box-sizing: border-box;
}
.propmo_Card_Box:first-child{
  padding-left: 85px;
  padding-right: 100px;
}
.propmo_Card_Box:last-child{
  padding-left: 100px;
}


.uniq-Title {
  font-weight: 800;
  font-size: 36px;
  line-height: 100%;
  color: #00bdf2;
  position: relative;
  z-index: 2;
}
.title_2 {
  font-weight: 200;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-top: 38px;
  margin-bottom: 10px;
  min-height: 52px;
  position: relative;
  z-index: 2;
}
.about_Btn {
  padding: 22px 40px 22px;
  background: #00bdf2;
  outline: none;
  border: none;
  color: #ffffff;
  margin-top: 90px;
  width: 100%;
}
.btn_Text {
  display: inline-block;
  color: #ffffff;
  font-weight: 800;
  font-size: 12px;
  margin-left: 40px;
  margin-top: 22px;
  margin-bottom: 23px;
  text-transform: uppercase;
}
.btn_Link {
  transition: border-color .3s;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}
@media (min-width: 1000px) {
  .btn_Link:hover {
    border-color: #fff;
  }
}

.promo_Card_Box {
  display: flex;
  justify-content: space-between;
}
.uniq_Title_Color_Style{
  font-weight: 800;
  font-size: 36px;
  line-height: 100%;
  color: #FF54C4;
  position: relative;
  z-index: 2;

}
.propmo_Card_Img_Box{
  width: 100%;
  display: flex;
  justify-content: center;
  height: 362px;
}
.propmo_Card_Img_Box img{height: 362px; margin-left: -80px; width: auto}
.propmo_Card_Box:first-child .propmo_Card_Img_Box img{height: 342px;}
.propmo_btn{position: relative; z-index: 2; padding-top: 50px;}

.propmo_Card_Box:first-child .propmo_Card_Img_Box {
  justify-content: flex-start;
}
.propmo_Card_Box:first-child .propmo_Card_Img_Box img{
  margin-left: -15px;
}


@media (max-width: 1680px) {
  .promo_Action_Box__title {
    font-size: calc(60 / 1680 * 100vw);
  }
  .unit {
    font-size: calc(24 / 1680 * 100vw);
    margin-top: calc(29 / 1680 * 100vw);
  }
  .propmo_Card_Box{
    margin-top: calc(90 / 1680 * 100vw);
  }
  .propmo_Card_Box:first-child{
    padding-left: calc(85 / 1680 * 100vw);
    padding-right: calc(100 / 1680 * 100vw);
  }
  .propmo_Card_Box:last-child{
    padding-left: calc(100 / 1680 * 100vw);
  }
  .titles {
    font-size: calc(36 / 1680 * 100vw);
  }
  .uniq-Title {
    font-size: calc(36 / 1680 * 100vw);
  }
  .title_2 {
    font-size: calc(14 / 1680 * 100vw);
    line-height: 1.2;
    margin-top: calc(38 / 1680 * 100vw);
    margin-bottom: calc(10 / 1680 * 100vw);
    min-height: calc(52 / 1680 * 100vw);
  }
  .about_Btn {
    padding: calc(22 / 1680 * 100vw) calc(40 / 1680 * 100vw) calc(22 / 1680 * 100vw);
    margin-top: calc(90 / 1680 * 100vw);
  }
  .btn_Text {
    font-size: calc(12 / 1680 * 100vw);
    margin-left: calc(40 / 1680 * 100vw);
  }
  .uniq_Title_Color_Style{
    font-size: calc(36 / 1680 * 100vw);

  }
  .propmo_Card_Img_Box_Flex{
    margin-left: calc(-45 / 1680 * 100vw);
  }
  .propmo_btn{padding-top: calc(50 / 1680 * 100vw);}
  .propmo_Card_Img_Box{height: calc(362 / 1680 * 100vw);}
  .propmo_Card_Img_Box img{height: calc(362 / 1680 * 100vw); margin-left: calc(-80 / 1680 * 100vw);}
  .propmo_Card_Box:first-child .propmo_Card_Img_Box img{height: calc(342 / 1680 * 100vw);margin-left: -1vw;}
}
