.main{position: relative}
.main__bg{
    position: absolute;
    width: 100%; height: 100%;
    overflow: hidden;
    pointer-events: none;
}
.main__content{
    margin: 0 auto;
    padding-left: 40px;
    padding-right: 40px;
    max-width: 1560px;
    position: relative;
}

.main-content__bg{
    position: absolute;
    top: -200px; right: -400px;
    width: 100%; height: 100%;
    pointer-events: none;
}
.main-content__bg span{
    position: absolute;
    top: 0; left: 50%;
    width: 1547px; height: 1547px;
    margin-left: calc(-1547px / 2);
    background-image: url(../StoreMap/assets/bg.png?2);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 1547px;
}
.main-content__bg span:nth-child(1){
    animation: loader1 25s ease-in-out infinite;
}
.main-content__bg span:nth-child(2){
    background-size: 1289px;
    animation: loader2 27s ease-in-out infinite;
}
.main-content__bg span:nth-child(3){
    background-size: 1074px;
    animation: loader3 29s ease-in-out infinite;
}
.main-content__bg span:nth-child(4){
    background-size: 895px;
    animation: loader4 31s ease-in-out infinite;
}
.main-content__bg--2{
    opacity: .3;
    top: 1885px; left: 50%;
    transform: translateX(-1080px);
}

.main-content__bg--2 span{
    width: 1860px; height: 1860px;
    margin-left: calc(-1860px / 2);
    background-size: 1860px;
}
.main-content__bg span:nth-child(2){
    background-size: 1360px;
}
.main-content__bg span:nth-child(3){
    background-size: 1110px;
}
.main-content__bg span:nth-child(4){
    background-size: 920px;
}
.main-content__bg span:nth-child(5){
    background-size: 770px;
}

.main-icon{
    position: absolute;
    left: 50%; top: 0;
    width: 62px; height: 62px;
    background-position: 50% 50%;
}

@keyframes spin {
    0%  {transform: rotate(0deg);}
    100% {transform: rotate(-360deg);}
}
.main-icon img{
    position: absolute;
    top: 0; left: 0;
    max-width: 100%; max-height: 100%;
    animation: spin 11s linear infinite;
}
.main-icon1{top: 167px; margin-left: -733px;}
.main-icon2{top: 334px; margin-left: -50px;}
.main-icon3{top: 1006px; margin-left: -490px;}
.main-icon4{top: 1615px; margin-left: 44px;}
.main-icon5{top: 1894px; margin-left: -690px;}
.main-icon6{top: 2254px; margin-left: -655px;}
@media (max-width: 1680px) {
    .main-icon1{top: calc(167 / 1680 * 100vw); margin-left: calc(-733 / 1680 * 100vw);}
    .main-icon2{top: calc(334 / 1680 * 100vw); margin-left: calc(-50 / 1680 * 100vw);}
    .main-icon3{top: calc(1006 / 1680 * 100vw); margin-left: calc(-490 / 1680 * 100vw);}
    .main-icon4{top: calc(1615 / 1680 * 100vw); margin-left: calc(44 / 1680 * 100vw);}
    .main-icon5{top: calc(1894 / 1680 * 100vw); margin-left: calc(-690 / 1680 * 100vw);}
    .main-icon6{top: calc(2254 / 1680 * 100vw); margin-left: calc(-655 / 1680 * 100vw);}
    .main-icon {
        width: calc(62 / 1680 * 100vw); height: calc(62 / 1680 * 100vw);
    }
    .main-content__bg{
        top: calc(-200 / 1680 * 100vw); right: calc(-400 / 1680 * 100vw);
    }
    .main-content__bg span{
        width: calc(1547 / 1680 * 100vw); height: calc(1547 / 1680 * 100vw);
        margin-left: calc(calc(-1547 / 1680 * 100vw) / 2);
        background-size: calc(1547 / 1680 * 100vw);
    }
    .main-content__bg span:nth-child(2){
        background-size: calc(1289 / 1680 * 100vw);
    }
    .main-content__bg span:nth-child(3){
        background-size: calc(1074 / 1680 * 100vw);
    }
    .main-content__bg span:nth-child(4){
        background-size: calc(895 / 1680 * 100vw);
    }
    .main-content__bg--2{
        top: calc(1885 / 1680 * 100vw);
        transform: translateX(calc(-1080 / 1680 * 100vw));
    }
    .main-content__bg--2 span{
        width: calc(1860 / 1680 * 100vw); height: calc(1860 / 1680 * 100vw);
        margin-left: calc(calc(-1860 / 1680 * 100vw) / 2);
        background-size: calc(1860 / 1680 * 100vw);
    }
    .main-content__bg-2 span:nth-child(2){
        background-size: calc(1360 / 1680 * 100vw);
    }
    .main-content__bg-2 span:nth-child(3){
        background-size: calc(1110 / 1680 * 100vw);
    }
    .main-content__bg-2 span:nth-child(4){
        background-size: calc(920 / 1680 * 100vw);
    }
    .main-content__bg-2 span:nth-child(5){
        background-size: calc(770 / 1680 * 100vw);
    }
}

@media (max-width: 1000px) {
    .main-content__bg span{
        background-image: url(../StoreMap/assets/bg_mob_l.png?2);
    }
    /*
    .main-content__bg span{
        background-image: url(../StoreMap/assets/bg_mob.png?2);
    }
    .main-content__bg span:nth-child(2) {
        background-image: url(../StoreMap/assets/bg_mob2.png?2);
    }

     */


    .comp {
        /*
        top: 0;
        right: 0;
        width: calc(887 / 1680 * 100vw);
        height: calc(877 / 1680 * 100vw);
        position: relative;
         */
        position: relative;
        top: 0;
        right: 0;

        width: 340px;
        height: 285px;


        /*
        width: calc(340 / 375 * 100vw);
        height: calc(285 / 375 * 100vw);
        */


        margin: -25px auto 0 auto;

    }
    .coming_Soon_Box {
        padding: 0;
    }
    .main-content__bg span {
        display: none;
    }
    .main-content__bg span:first-child {
        display: block;
        /*
        width: calc(474 / 375 * 100vw);
        height: calc(481 / 375 * 100vw);
        margin-left: calc(calc(-474 / 375 * 100vw) / 2);

         */
        width: 474px;
        height: 481px;
        margin-left: -237px;
        background-size: contain;
        animation: none;
    }

    .main-content__bg {
        /*
        top: calc(-70 / 375 * 100vw);
        right: calc(-70 / 375 * 100vw);

         */

        top: -70px;
        right: -70px;
    }


    .main-content__bg--2 {
        top: calc(1885 / 1680 * 100vw);
        transform: translateX(calc(-1080 / 1680 * 100vw));
    }
    .main__content {
        padding-left: 0;
        padding-right: 0;
    }
    .main {
        position: relative;
        overflow: hidden;
    }

    .main-icon {
        /*
        width: calc(37 / 345 * 100vw);
        height: calc(37 / 345 * 100vw);

         */
        width: 37px;
        height: 37px;
    }

    .main-icon img {
        animation: none;
    }
    .main-icon1 {
        /*
        top: calc(97 / 345 * 100vw);
        margin-left: calc(-163 / 375 * 100vw);

         */
        top: 97px;
        margin-left: -163px;
    }
    .header__menu-mobile .page-btn {
        max-width: 100%;
        display: block;
        width: auto;
    }
    .coming_Soon_Box {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .coming_Soon_Timer {
        order: 2;
    }
    .comin_Soon_Promo {
        font-size: 18px;
        padding: 0 40px;
        padding-right: 30px;
        margin: 0;
    }
    .coming_Soon_Contect {
        font-size: 28px;
        margin: 10px 0 0 0;
        padding: 0 40px;
    }
    .comin_Soon_Title {
        font-size: 42px;
        margin: 0;
        padding: 0 40px;

    }
    .coming_Soon_Limit {
        font-size: 18px;
        margin: 10px 0 0 0;
        padding: 0 40px;
    }
    .page-btn.coming_Soon_Btn {
        margin-top: 24px;
        width: calc(100vw - 160px);
        max-width: 400px;
    }
    .main-icon2 {
        top: 481px;
        margin-left: 115px;
        width: 34px;
        height: 34px;
    }
    .timer_Box {
        width: 295px;
        height: 100px;
        margin: 30px auto 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 15px;
    }
    .coming_Soon_Timer {
        margin: auto;
    }
    .timer_Item_First_Block {
        font-size: 16px;
        margin-bottom: 10px;
    }
    .tim_Item {
        font-size: 25px;
        padding: 0;
    }
    .tim_Item:not(:last-child):after {
        font-weight: 100;
        margin: 0 8px;
    }
    .tim_Item .item {
        font-size: 15px;
        padding: 0;
        transform: translateY(15px);
    }
    .tim_Item:not(:last-child) .item
    {
        margin-left: -32px;
    }
    .tim_Item:first-child .item
    {
        margin-left: -28px;
    }
    .tim_Item:last-child .item
    {
        margin-left: -21px;
    }
    .promo_Action_Box {
        padding: 20px 40px 0 40px;
        margin-top: 53px;
        position: relative;
    }
    .promo_Action_Box__title {
        font-size: 30px;
    }
    .unit {
        font-size: 18px;
        margin-top: 23px;
        line-height: 22px;
    }
    .unit br {
        display: none;
    }
    .promo_Card_Box {
        display: block;
        margin-top: 36px;
    }
    .propmo_Card_Box:first-child {
        padding-left: 0;
        padding-right: 0;
    }
    .uniq_Title_Color_Style {
        font-size: 24px;
    }
    .uniq_Title_Color_Style br {
        display: none;
    }
    .propmo_Card_Box {
        margin-top: 0;
    }
    .propmo_Card_Box {
        display: block;
        width: 100%;
    }
    .title_2 {
        font-size: 16px;
        line-height: 1.2;
        margin-top: 13px;
        margin-bottom: 30px;
        min-height: auto;
    }
    .title_2 br {
        display: none;
    }
    .propmo_Card_Img_Box {
        height: auto;
    }
    .propmo_Card_Box:first-child .propmo_Card_Img_Box img {
        height: auto;
        max-width: 87%;
        margin: auto;
        display: block;
    }

    .promo_Action_Box:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: -300px;
        width: 375px;
        height: 803px;
        background: url("../StoreMap/assets/purp.png?2") center center no-repeat;
        background-size: contain;
        pointer-events: none;
    }
    .propmo_btn {
        padding-top: 10px;
    }
    .propmo_btn .page-btn {
        display: block;
        margin-top: 10px;
    }
    .page-btn {
        max-width: 400px;
    }
    .btn_Text {
        font-size: 12px;
        margin-left: 0;
        text-align: center;
        display: block;
        margin-top: 22px;
        margin-bottom: 23px;
        font-weight: normal;
    }
    .uniq-Title {
        font-size: 24px;
    }
    .propmo_Card_Box:last-child {
        padding-left: 0;
        margin-top: 45px;
    }
    .propmo_Card_Img_Box img {
        height: auto;
        margin-left: 0;
        max-width: 68%;
    }
    .footer-bottom-el {
        margin-bottom: 5px;
        display: inline-block;
        font-weight: 200;
        color: #fff;
    }
    .footer-top, .footer-bottom {
        font-weight: 200;
    }
    .footer-soc a {
        padding: 0px 11px;
    }
    .promo_Action_Box:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: -300px;

        width: 375px;
        height: 603px;
        background: url("../StoreMap/assets/blue.png?2") center center no-repeat;
        background-size: contain;
        pointer-events: none;
    }


    .icon_Wrapper {
        margin-top: 0;
        width: auto;
        flex-direction: column;
    }


    .slick-dots {
        display: flex !important;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        position: absolute;
        width: auto;
        left: 20px;
        right: 20px;
        bottom: -80px;
    }
    .slick-dots button {
        display: none;
    }
    .slick-dots li {
        width: 10px;
        height: 10px;
        border: 2px solid #FFFFFF;
        box-sizing: border-box;
        margin: 0px 5px;
        border-radius: 50%;
    }
    .slick-dots li.slick-active {
        background: #00BDF2;
        border-color: #00BDF2;
    }

    .main-icon3, .main-icon4, .main-icon5, .main-icon6 {
        display: none;
    }
    .prize-icon2, .prize-icon3, .prize-icon4 {
        display: none;
    }

}
