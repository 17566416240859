.prize_Fund_Box {
    position: relative;
}
.prize_Fund_Box img{height: auto !important;}
.prize_Fund_Box__bg{
    position: absolute;
    top: -600px; left: 0; width: 100%; height: 2000px;
    overflow: hidden;
    z-index: -1;
    pointer-events: none;
}
.prize_Fund_Box__bg__pink{
    top: 50px; left: 50%; margin-left: 0px;
}
.prize_Fund_Box__bg__blue{
    top: 700px; left: 50%; margin-left: -1600px;
}
.prize-icon2{
    margin-top: 870px;
    margin-left: 244px;
}
.prize-icon3{
    margin-top: 1210px;
    margin-left: -400px;
}
.prize-icon4{
    margin-top: 980px;
    margin-left: -670px;
}

.prize_Fund_Box__title {
    font-style: normal;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-size: 50px;
}
.prize_Fund_Box_text-top {
    font-weight: 200;
    text-align: center;
    color: #ffffff;
    font-size: 24px;
    margin-top: 29px;
}
.prize_Fund_Box_text-bottom {
    text-align: center;
    color: #fff;
    font-weight: 200;
    margin-top: 67px;
    font-size: 18px;
}
.prize_Fund_Box_text-middle{
    font-size: 84px;
    margin-bottom: 85px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 100;
    text-align: center;
}
.prize_Fund_Box_text-other {
    margin-top: 40px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 100;
    font-size: 24px;
    color: #fff;
}

.prize-row{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
}
.prize-row--1{padding-right: 430px;}
.prize-row--1 img{width: 470px;}
.prize-row--2{padding-left: 430px;}
.prize-row--2 img{width: 628px;}
.prize-row span{
    color: #fff;
    font-size: 24px;
    font-weight: 800;
    text-transform: uppercase;
}


.slier_Box {
    padding-top: 50px;
}

.CaruselCard_icon_Wrapper{
    padding: 0 20px;
}

.CaruselCard_icon_Box{
    width: 250px;
    height: 310px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding-top: 24px;
    padding-bottom: 40px;
    border-radius: 5px;
    box-sizing: border-box;
}
.CaruselCard_icon_Text{
    text-align: center;
    width: 195px;
    height: 90px;
    font-size: 18px;
    font-weight: 700;
}
.CaruselCard_icon_Box img{
    width: 112px;
    height: auto;
}
.slider-tabs{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}
.slider-tabs > div{
    display: flex;
    align-items: center;
    border-radius: 5px;
    background: linear-gradient(-245deg, #842D70 16.58%, #0E83F0 81.79%);
    padding: 2px;
    /*background: linear-gradient(245.5deg, #842D70 16.89%, #0E83F0 93.78%);*/
}
.slider-tabs span{
    border-radius: 0 5px 5px 0;
    background: #000;
    padding: 22px 40px;
    color: #fff;
    transition: 300ms;
    cursor: pointer;
    text-transform: uppercase;
}

.slider-tabs span:first-child {
    border-radius: 5px 0 0 5px;
}

.slider-tabs span[data-active="true"] {
    background: transparent;
}

.coupon-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -10px;
    margin-bottom: 35px;
}
.coupon-tabs > div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: relative;
}
.coupon-tabs .coupon-item {
    margin: 0 25px;
    opacity: 0.5;
    cursor: pointer;
}
.coupon-tabs .coupon-item.active {
    opacity: 1;
    cursor: default;
}
.coupon-tabs big, .coupon-tabs small {
    display: block;
    text-align: left;
    white-space: nowrap;
}
.coupon-tabs big {
    text-transform: uppercase;
    font-size: 24px;
}
.coupon-tabs small {
    font-weight: 100;
    font-size: 14px;
}
.coupon-tabs .coupon-arrow {
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 50%;
    width: 104px;
    height: 103px;
    transition: 300ms;
    cursor: pointer;
    z-index: 2;
}
.coupon-tabs .coupon-arrow svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 54px;
    height: 55px;
}
.coupon-tabs .coupon-prev {
    left: -70px;
    background-image: url("assets/shadow-prev.png?2");
    transform: translate(-20px, -50%);
}
.coupon-tabs .coupon-prev svg {
    margin-left: -10px;
}
.coupon-tabs .coupon-next {
    right: -70px;
    background-image: url("assets/shadow-next.png?2");
    transform: translate(20px, -50%);
}
.coupon-tabs .coupon-arrow.disabled {
    background: none;
    opacity: .5;
    cursor: default;
    pointer-events: none;
}
.coupon-tabs + .slider {
    max-width: 1450px;
    margin-left: auto;
    margin-right: auto;
}
.coupon-tabs + .slider .CaruselCard_icon_Text {
    font-weight: 400;
}
.coupon-tabs + .slider .CaruselCard_icon_Box img {
    width: 150px;
}

@media (max-width: 1680px) {
    .prize_Fund_Box__bg{
        top: calc(-600 / 1680 * 100vw); height: calc(2000 / 1680 * 100vw);
    }
    .prize_Fund_Box__bg__pink{
        top: calc(50 / 1680 * 100vw);
    }
    .prize_Fund_Box__bg__blue{
        top: calc(700 / 1680 * 100vw);
        margin-left: calc(-1600 / 1680 * 100vw);
    }
    .prize-icon2{
        margin-top: calc(870 / 1680 * 100vw);
        margin-left: calc(244 / 1680 * 100vw);
    }

    .prize-icon3{
        margin-top: calc(1210 / 1680 * 100vw);
        margin-left: calc(-400 / 1680 * 100vw);
    }
    .prize-icon4{
        margin-top: calc(980 / 1680 * 100vw);
        margin-left: calc(-670 / 1680 * 100vw);
    }

    .prize_Fund_Box__title {
        font-size: calc(50 / 1680 * 100vw);
    }
    .prize_Fund_Box_text-top {
        font-size: calc(24 / 1680 * 100vw);
        margin-top: calc(29 / 1680 * 100vw);
    }
    .prize_Fund_Box_text-bottom {
        margin-top: calc(67 / 1680 * 100vw);
        font-size: calc(18 / 1680 * 100vw);
    }
    .prize_Fund_Box_text-middle{
        font-size: calc(84 / 1680 * 100vw);
        margin-bottom: calc(85 / 1680 * 100vw);
    }

    .prize-row--1{padding-right: calc(430 / 1680 * 100vw);}
    .prize-row--1 img{width: calc(470 / 1680 * 100vw);}
    .prize-row--2{padding-left: calc(430 / 1680 * 100vw);}
    .prize-row--2 img{width: calc(628 / 1680 * 100vw);}
    .prize-row span{
        font-size: calc(24 / 1680 * 100vw);
    }


    .slier_Box {
        padding-top: calc(50 / 1680 * 100vw);
    }

    .CaruselCard_icon_Wrapper{
        padding: 0 calc(20 / 1680 * 100vw);
    }

    .CaruselCard_icon_Box{
        width: calc(250 / 1680 * 100vw);
        height: calc(310 / 1680 * 100vw);
        padding-top: calc(24 / 1680 * 100vw);
        padding-bottom: calc(40 / 1680 * 100vw);
    }
    .CaruselCard_icon_Text{
        width: calc(195 / 1680 * 100vw);
        height: calc(90 / 1680 * 100vw);
        font-size: calc(18 / 1680 * 100vw);
    }
    .CaruselCard_icon_Box img{
        width: calc(112 / 1680 * 100vw);
    }

    .slider-tabs{
        margin-bottom: calc(50 / 1680 * 100vw);
    }
    .slider-tabs span{
        padding: calc(22 / 1680 * 100vw) calc(40 / 1680 * 100vw);
        font-size: calc(16 / 1680 * 100vw);
    }

}

@media (max-width: 1000px) {
    .prize_Fund_Box__title {
        font-size: 30px;
    }
    .prize_Fund_Box_text-top {
        font-size: 18px;
        margin-top: 19px;
    }
    .prize_Fund_Box {
        position: relative;
        padding: 20px 40px 0 40px;

    }
    .prize_Fund_Box_text-top br {
    }

    .prize-row--1, .prize-row--2 {
        padding-right: 0;
        display: block;
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 0;
    }

    .prize-row span {
        font-size: 18px;
        display: block;
        text-align: center;
    }
    .prize-row span br {
        display: none;
    }
    .prize-row--1 img, .prize-row--2 img {
        width: 100%;
        margin: auto;
        max-width: 400px;
        display: block;
    }
    .prize-row--2 {
        margin-top: 40px;

    }

    .prize-row--1, .prize-row--2 {
    }
    .prize_Fund_Box_text-middle {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 43px;
        margin-top: 37px;
    }
    .slider-tabs {
        margin-bottom: 29px;
        padding: 0 40px;
    }
    .slider-tabs span {
        padding: 0 20px;
        font-size: 12px;
        width: 50%;
        text-align: center;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .slider-tabs > div {
        width: 100%;
    }
    .CaruselCard_icon_Box {
        width: 275px;
        height: 312px;
        padding: 20px 50px;
        overflow: hidden;
        margin: 0 auto;
    }
    .CaruselCard_icon_Text {
        width: auto;
        height: auto;
        font-size: 18px;
    }
    .CaruselCard_icon_Box img {
        width: auto;
        height: 120px;
        max-width: 100%;
        max-height: 100%;
        display: block;
        height: auto;
    }
    .CaruselCard_icon_Box img+img {
        width: auto;
        height: 32px;
    }

    .CaruselCard_icon_Wrapper {
        padding: 0 5px;
    }
    .points__text {
        padding: 0 40px;
    }
    .prize_Fund_Box_text-bottom {
        margin-top: 26px;
        font-size: 18px;
        padding: 0 40px;
    }

    .coupon-tabs > div {
        flex-wrap: wrap;
        padding: 0 110px;
    }
    .coupon-tabs big {
        text-align: center;
    }
    .coupon-tabs small {
        text-align: center;
    }
    .coupon-tabs .coupon-item {
        width: 100%;
        margin: 0 0 20px 0;
    }
    .coupon-tabs .coupon-item:last-child {
        margin-bottom: 0;
    }
    .coupon-tabs .coupon-next {
        right: 10px;
    }
    .coupon-tabs .coupon-prev {
        left: 10px;
    }
    .coupon-tabs {
        margin-top: 10px;
        margin-bottom: 10px;
    }

}
@media (max-width: 400px) {
    .slider-tabs {
        padding: 0 20px;
    }
}
